  <div class="container-fluid footer1 text-justify">
    <div class="row">
      
      
          
           
    </div>
  </div>
  <!--end-->



  <!--start-->
  <div class="container-fluid footer2">
    <div class="row p-3">
      <div class="col-lg-10 col-md-12 footer2content1">
        <div class="row">
          
          <div class="col-lg-6 col-md-12">
            <div class="input-group input-group-lg">
              <!-- <input type="text" class="form-control" placeholder="Your Email Address" aria-label="Your Email Address"
                aria-describedby="button-addon2"> -->
              <div class="input-group-append">
                <!-- <button class="btn btn-outline-secondary" type="button" id="button-addon2"
                  style="border:none;color :black ;background-color:rgb(253,128,36);">{{'footer.continue' | translate}}</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12 mt-3 text-justify" dir="auto">
        <h2>{{'footer.FOLLOW' | translate}}</h2>
        <div class="d-flex py-3">

          <a class="mr-1" href="https://facebook.com/series7tutors">
            <img src="assets\images\facebook-94eac66c1910a1b2ef861ad82c685d68.png">
          </a>
          <a class="mr-1" href="javascript:void(0)">
            <img src="assets\images\twitter-406b34968a0833ec9d67c03d0f96704c.png">
          </a>
          <a class="mr-1"  href="https://instagram.com/series7whisperer">
            <img src="assets\images\instagram-56ae5054868540f5754edc4a5c25d9c7.png">
          </a>
          <a class="mr-1" href="https://www.youtube.com/c/Series7Exam">
            <img src="assets\images\youtube-9e7818b52c1ef02dfa652fe32d028ce5.png">
          </a>
        </div>
      </div>


    </div>

  </div>
  <!--end-->

  <!--start-->
  
  <!--end-->

  <!--start-->
  <div class="container-fluid footer4" dir="auto">
    <div class="row p-3">
      <div>
        <img src="assets\images\newlogo.png" id="logo">
      </div>
      <div>
        <!-- <button>
          <img src="assets\images\us-4ebaed6a76e4d7930e0b2c9e86a43389.png">
          {{'footer.changeRegion' | translate}} </button>
      </div> -->

    </div>
    <div class="row px-3 py-2">
      <ul>
        <!-- <li><a href="javascript:void(0)"><strong>{{'footer.PrivacyPolicy' | translate}} </strong></a></li> -->
        <!-- <li><a href="javascript:void(0)"><strong>{{'footer.Cookies'  | translate}}</strong></a></li> -->
        <!-- <li><a href="javascript:void(0)"><strong>{{'footer.Settings' | translate}}</strong></a></li> -->
        <!-- <li><a href="javascript:void(0)"><strong>{{'footer.Settings' | translate}}</strong></a></li> -->
        <!-- <li><a href="javascript:void(0)"><strong>{{'footer.TermsUse' | translate}}</strong></a></li> -->
        <!-- <li><a href="javascript:void(0)"><strong>{{'footer.DigitalWellbeing' | translate}}</strong></a></li> -->
        <!-- <li><a href="javascript:void(0)"><strong>{{'footer.DoInformation' | translate}}</strong></a></li> -->
        <!-- <li><a href="javascript:void(0)"><strong>{{'footer.Accessibility' | translate}}</strong></a></li> -->
      </ul>
    </div>
    <div class="row px-3 py-3 lastfooter mb-3">
      <p>{{'footer.parag' | translate}}</p>

    </div>


  </div>