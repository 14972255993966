<!-- <div class="container-fluid text-justify" dir="auto" id="banner">
    <div class="row d-flex justify-content-center">
        <p>
            <img src="assets\images\40416-202011-Sitewide-Banner.png">
            <small>{{'Home.Skating'|translate}}</small>
            <a href="javascript:void(0)"> {{'VIP.shop'|translate}}</a>
        </p>

    </div>
</div> -->
<!--end-->


<div class="container-fluid firstbar text-justify" dir="auto" dir="auto">
    <div class="row py-3">
        <ul class="breadcrumb bg-white w-100 m-0">
            <li class="breadcrumb-item"><a [routerLink]="['/Home']">{{'header.Home'|translate}}</a></li>
            <!-- <li class="breadcrumb-item"><a href="javascript:void(0)">{{product?.relatedProducts[0]}}</a></li> -->
            <li *ngIf="translate.currentLang=='en'" class="breadcrumb-item active">{{product?.name}}</li>
            <li *ngIf="translate.currentLang=='ar'" class="breadcrumb-item active">{{product?.arabicName}}</li>
        </ul>
    </div>
</div>


<!--start-->
<div class="container-fluid product text-justify" dir="auto" dir="auto">
    <div class="row p-4">
        <div class="col-md-1 picelproduct">
            <img *ngFor="let item of product?.images;let indexOfelement=index;" src={{item}}
                (click)="ChangeImage(item)">
        </div>
        <div class="col-md-8">
            <div class=" picelproductkbera product1">
                <img src={{product?.image}} id="Picelproductkbera">
            </div>
        </div>
        <div class="col-md-3 product2 px-3 font-weight-bold">
            <div class="my-3">
                <!-- <img src={{product?.logoImage}}> -->
                <!-- <img src={{getlogoByID(product?.categoryID)}}> -->
            </div>
            <div class="mb-3">
                <h1 *ngIf="translate.currentLang=='en'">{{product?.name}}</h1>
                <h1 *ngIf="translate.currentLang=='ar'">{{product?.exams}}</h1>
                <h1 *ngIf="translate.currentLang=='ar'">{{product?.schedule}}</h1>

            </div>
            <div class="mb-3">
                <!-- <span class="my-1" id="stars">
                    <svg width="100%" height="100%" viewBox="0 0 50 49">
                        <g stroke="none" stroke-width="1" fill="#FFD500">
                            <path
                                d="M49.9,18.1 C49.3,16.2 47.1,15.9 47.1,15.9 L34,13.9 L28,2 C28,2 27,0 25,0 C23,0 22,2 22,2 L16.1,13.9 L3,15.9 C3,15.9 0.8,16.2 0.2,18.1 C-0.4,20 1.2,21.6 1.2,21.6 L10.8,31 L8.3,44 C8.3,44 7.9,46.2 9.5,47.4 C11.1,48.6 13.1,47.6 13.1,47.6 L25,41.8 L37.1,47.7 C37.1,47.7 39.1,48.7 40.7,47.5 C42.3,46.3 41.9,44.1 41.9,44.1 L39.4,31.1 L49,21.7 C48.9,21.6 50.5,20 49.9,18.1 Z"
                                fill-rule="nonzero"></path>
                        </g>
                    </svg>
                    <svg width="100%" height="100%" viewBox="0 0 50 49">
                        <g stroke="none" stroke-width="1" fill="#FFD500">
                            <path
                                d="M49.9,18.1 C49.3,16.2 47.1,15.9 47.1,15.9 L34,13.9 L28,2 C28,2 27,0 25,0 C23,0 22,2 22,2 L16.1,13.9 L3,15.9 C3,15.9 0.8,16.2 0.2,18.1 C-0.4,20 1.2,21.6 1.2,21.6 L10.8,31 L8.3,44 C8.3,44 7.9,46.2 9.5,47.4 C11.1,48.6 13.1,47.6 13.1,47.6 L25,41.8 L37.1,47.7 C37.1,47.7 39.1,48.7 40.7,47.5 C42.3,46.3 41.9,44.1 41.9,44.1 L39.4,31.1 L49,21.7 C48.9,21.6 50.5,20 49.9,18.1 Z"
                                fill-rule="nonzero"></path>
                        </g>
                    </svg>
                    <svg width="100%" height="100%" viewBox="0 0 50 49">
                        <g stroke="none" stroke-width="1" fill="#FFD500">
                            <path
                                d="M49.9,18.1 C49.3,16.2 47.1,15.9 47.1,15.9 L34,13.9 L28,2 C28,2 27,0 25,0 C23,0 22,2 22,2 L16.1,13.9 L3,15.9 C3,15.9 0.8,16.2 0.2,18.1 C-0.4,20 1.2,21.6 1.2,21.6 L10.8,31 L8.3,44 C8.3,44 7.9,46.2 9.5,47.4 C11.1,48.6 13.1,47.6 13.1,47.6 L25,41.8 L37.1,47.7 C37.1,47.7 39.1,48.7 40.7,47.5 C42.3,46.3 41.9,44.1 41.9,44.1 L39.4,31.1 L49,21.7 C48.9,21.6 50.5,20 49.9,18.1 Z"
                                fill-rule="nonzero"></path>
                        </g>
                    </svg>
                    <svg width="100%" height="100%" viewBox="0 0 50 49">
                        <g stroke="none" stroke-width="1" fill="#FFD500">
                            <path
                                d="M49.9,18.1 C49.3,16.2 47.1,15.9 47.1,15.9 L34,13.9 L28,2 C28,2 27,0 25,0 C23,0 22,2 22,2 L16.1,13.9 L3,15.9 C3,15.9 0.8,16.2 0.2,18.1 C-0.4,20 1.2,21.6 1.2,21.6 L10.8,31 L8.3,44 C8.3,44 7.9,46.2 9.5,47.4 C11.1,48.6 13.1,47.6 13.1,47.6 L25,41.8 L37.1,47.7 C37.1,47.7 39.1,48.7 40.7,47.5 C42.3,46.3 41.9,44.1 41.9,44.1 L39.4,31.1 L49,21.7 C48.9,21.6 50.5,20 49.9,18.1 Z"
                                fill-rule="nonzero"></path>
                        </g>
                    </svg>
                    <svg width="100%" height="100%" viewBox="0 0 50 49">
                        <g stroke="none" stroke-width="1" fill="#CACACA">
                            <path
                                d="M49.9,18.1 C49.3,16.2 47.1,15.9 47.1,15.9 L34,13.9 L28,2 C28,2 27,0 25,0 C23,0 22,2 22,2 L16.1,13.9 L3,15.9 C3,15.9 0.8,16.2 0.2,18.1 C-0.4,20 1.2,21.6 1.2,21.6 L10.8,31 L8.3,44 C8.3,44 7.9,46.2 9.5,47.4 C11.1,48.6 13.1,47.6 13.1,47.6 L25,41.8 L37.1,47.7 C37.1,47.7 39.1,48.7 40.7,47.5 C42.3,46.3 41.9,44.1 41.9,44.1 L39.4,31.1 L49,21.7 C48.9,21.6 50.5,20 49.9,18.1 Z"
                                fill-rule="nonzero"></path>
                        </g>
                    </svg>
                </span> -->
                <i *ngFor="let i of [].constructor(overallRating())" class="fa fa-star text-warning"
                    aria-hidden="true"></i>
                <a href="javascript:void(0)" class="mx-2 py-2">{{reviewNumber}} {{'Product.Reviews'|translate}}</a>

            </div>
            <!-- <div class="mb-3 lonazrq p-2 text-justify" dir="auto">
                <img class="float-left mr-2" src="assets\images\40416-PDPPage-202011-Promo-Badge.png">
                <span>{{'Product.FREE'|translate}}*</span>
                <br>
                <a href="javascript:void(0)">{{'VIP.more'|translate}} </a>

            </div> -->
            <div>
                <h1>{{product?.price | currency}}/hr</h1>
                <h3>{{product?.bio}}</h3>

                <!-- <p style="color: rgb(14, 218, 14);">{{'Checkout.Available'|translate}}</p> -->
            </div>
            <div class="d-flex mb-3">
                <!-- <div class="d-flex">
                    <div class="border rounded-left py-2 px-3 mx-0" id="minus" (click)="minus()"> -</div>
                    <div class="border py-2 px-4 mx-0" id="count">{{count}}</div>
                    <div class="border rounded-right py-2 px-3 mx-0" id="plus" (click)="plus()">+</div>
                </div> -->
                <div class="mx-2 py-2">
                    <!-- <span> {{'Limit'|translate}} {{product?.stock}}</span> -->
                    <!--
                    <button class="mx-2" id="ipopover" href="#" title="limit" data-toggle="popover" data-trigger="focus"
                        data-content=" We restrict the limit a household can buy in order to be fair 
                        to all of our fans. If you’ve already reached that limit through previous
                         orders your order may be cancelled.">i</button>
                   -->
                </div>


            </div>
            <!-- <div class="mb-3 text-justify" dir="auto">
                <button *ngIf="product?.stock<=0" disabled (click)="addToBag(product?.id)" type="button"
                    class="btn w-100 py-3 " style="background-color: #fd8024; font-size: 0.85rem;">
                    <strong>{{'RecommendedForYou.BagOut'|translate}}</strong>
                </button>
                <button *ngIf="product?.stock>0" (click)="addToBag(product?.id)" type="button" class="btn w-100 py-3 "
                    style="background-color: #fd8024; font-size: 0.85rem;">
                    <strong>{{'RecommendedForYou.Bag'|translate}}</strong>
                </button>
            </div>
            <div class=" py-3 linkeswd border-bottom text-justify" dir="auto">
                <svg id="heart" width="100%" height="100%" viewBox="0 0 40 40" alt=""
                    class="WishlistButtonstyles__StyledWishlistIcon-d720r1-1 eDfAts">
                    <rect fill="#F8F8F8" width="40" height="40" rx="20"></rect>
                    <path class="d-none"
                        d="M19.986 30l.014-.014.014.014 8.223-8.116-.018-.019a5.678 5.678 0 0 0 1.78-4.126C30 14.569 27.398 12 24.187 12A5.829 5.829 0 0 0 20 13.762 5.827 5.827 0 0 0 15.815 12C12.604 12 10 14.569 10 17.739a5.68 5.68 0 0 0 1.782 4.126"
                        fill="#006DB7"></path>
                    <path
                        d="M26.84 20.417L20 27.204l-6.84-6.787A3.67 3.67 0 0 1 12 17.739C12 15.677 13.71 14 15.815 14a3.82 3.82 0 0 1 2.754 1.159l1.43 1.467 1.433-1.467A3.818 3.818 0 0 1 24.186 14C26.289 14 28 15.677 28 17.739a3.673 3.673 0 0 1-1.16 2.678M19.986 30l.014-.014.014.014 8.223-8.116-.018-.019a5.678 5.678 0 0 0 1.78-4.126C30 14.569 27.398 12 24.187 12A5.829 5.829 0 0 0 20 13.762 5.827 5.827 0 0 0 15.815 12C12.604 12 10 14.569 10 17.739a5.68 5.68 0 0 0 1.782 4.126"
                        fill="#006DB7">
                    </path>
                </svg>
                <a role="button" (click)="addToWishlist(product?.id)" class="fontso8yr"
                    href="javascript:void(0)">{{'Product.AddWishlist'|translate}}</a>
            </div>
            <div class=" py-3 linkeswd border-bottom text-justify" dir="auto">
                <a data-toggle="collapse" data-target="#demo">{{'Product.Check'|translate}}</a>
                <div id="demo" class="collapse">
                    {{'Product.address'|translate}}
                    <div class=" mb-3 input-group input-group-lg">
                        <input type="text" class="form-control" placeholder="Enter a city and state or zip code"
                            aria-describedby="button-addon2">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary border rounded-right" type="button"
                                id="button-addon2"
                                style="border:none;color :black ;background-color:rgb(255, 255, 255);">
                                <i class="fas fa-search mt-1 mx-1" style="color:rgb(0,109,183);"></i>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="py-3 text-justify" dir="auto">
                <span>{{'Product.Shop'|translate}}</span><br>
                <a class="pr-2" href="javascript:void(0)" *ngFor="let item of product?.relatedProducts">{{item}}</a>
            </div>
        </div>
    </div>

</div> -->
<!--end-->


<!--start-->
<!-- <div class="container-fluid text-justify" dir="auto">
    <div class="row " style="background-color: white; border-bottom: rgb(206, 205, 205) solid 1px;">
        <div class="col-12 d-flex justify-content-center flex-wrap text-center">

            <div class=" product-details">
                <div class="product-detailssvg pb-3">
                    <span class="text-center">
                        <svg width="34px" height="40px" aria-hidden="true" data-di-res-id="4b76a8eb-5528fdd0"
                            data-di-rand="1609076026408">
                            <g style="mix-blend-mode:darken" fill="#757575" fill-rule="evenodd">
                                <path
                                    d="M32.606 29.532c.319 0 .576.127.576.282v.585c0 .155-.257.281-.576.281H.895c-.32 0-.578-.126-.578-.281v-.585c0-.155.258-.282.578-.282h31.71zM15.737 14.597c.071 0 .129.126.129.281v.585c0 .155-.058.281-.13.281H8.63c-.072 0-.13-.126-.13-.281v-.585c0-.155.058-.281.13-.281h7.107zM25.45 14.597c.072 0 .13.126.13.281v.585c0 .155-.058.281-.13.281h-7.106c-.072 0-.13-.126-.13-.281v-.585c0-.155.058-.281.13-.281h7.107z">
                                </path>
                                <path
                                    d="M1.821 21.572H32.18v16.606H1.82V21.572zM1.195 20A1.19 1.19 0 0 0 0 21.186v17.592a1.19 1.19 0 0 0 1.195 1.186h31.54a1.19 1.19 0 0 0 1.193-1.186V21.186A1.19 1.19 0 0 0 32.734 20H1.195zM7.856 8.485c-.79.11-1.183.505-1.177 1.186v3.741c.342.047 1.524.09 1.524 1.559V9.885H25.9v3.874c.291-1.068.765-1.132 1.421-.193V9.671c0-.655-.527-1.186-1.177-1.186H7.856z">
                                </path>
                                <path
                                    d="M7.34 18.919c-1.64 0-1.634-.814-1.634-1.042 0-1.052 1.071-3.36 1.614-3.48.593.12 1.665 2.428 1.666 3.48 0 .23-.005 1.042-1.646 1.042M7.3 12.12C6.272 12.334 4.25 16.43 4.25 18.3c0 .403-.008 1.847 3.085 1.847 3.097 0 3.106-1.441 3.106-1.847-.004-1.869-2.025-5.968-3.142-6.178M16.959 18.919c-1.64 0-1.635-.814-1.635-1.042 0-1.052 1.072-3.36 1.615-3.48.593.12 1.664 2.428 1.666 3.48 0 .23-.005 1.042-1.646 1.042m-.042-6.798c-1.026.213-3.048 4.309-3.048 6.178 0 .403-.008 1.847 3.085 1.847 3.096 0 3.105-1.441 3.105-1.847-.004-1.869-2.025-5.968-3.142-6.178M26.646 18.919c-1.64 0-1.634-.814-1.634-1.042 0-1.052 1.072-3.36 1.614-3.48.593.12 1.665 2.428 1.667 3.48 0 .23-.005 1.042-1.647 1.042m-.041-6.798c-1.025.213-3.048 4.309-3.048 6.178 0 .403-.009 1.847 3.084 1.847 3.097 0 3.105-1.441 3.105-1.847-.002-1.869-2.024-5.968-3.141-6.178M16.959 7.525c-1.64 0-1.635-.814-1.635-1.041 0-1.053 1.072-3.36 1.615-3.482.593.12 1.664 2.429 1.666 3.482 0 .228-.005 1.04-1.646 1.04M16.917.728C15.891.94 13.87 5.036 13.87 6.905c0 .404-.008 1.847 3.085 1.847 3.096 0 3.105-1.441 3.105-1.847-.004-1.869-2.025-5.968-3.142-6.178">
                                </path>
                            </g>
                        </svg>

                    </span>
                </div>
                <h1>8+</h1>
                <p>{{'Product.Ages'|translate}}</p>
            </div>
            <div class="product-details text-justify" dir="auto">
                <div class="product-detailssvg pt-1  pb-3">
                    <span class="text-center">
                        <svg width="33px" height="40px" aria-hidden="true" data-di-res-id="4b76a8eb-b7f8e925"
                            data-di-rand="1609076026408">
                            <g style="mix-blend-mode:darken" fill-rule="nonzero" stroke="#757575" fill="none">
                                <path stroke-width="1.727" fill="#FFF"
                                    d="M1.184 9.433l15.224-7.281 15.59 7.194V30.49L17.418 39 1.184 30.577z"></path>
                                <path
                                    d="M23.23 3.544C22.864 2.141 19.925 1 16.526 1c-3.675 0-6.798 1.228-6.89 2.895 0 0 .184 4.124.184 4.211 0 2.018 3.307 3.422 6.798 3.422 3.582 0 6.797-1.404 6.797-3.422v-3.07s.184-.965-.183-1.492z"
                                    stroke-width="1.152" fill="#FFF"></path>
                                <path
                                    d="M10.094 4.334c.092-1.58 2.94-2.895 6.43-2.895 3.491 0 6.34 1.316 6.431 2.895.092 1.667-2.756 2.983-6.43 2.983-3.675 0-6.523-1.404-6.43-2.983h0z"
                                    stroke-width="1.152"></path>
                                <path stroke-width="1.727" d="M2 10l15 7.222V39M17 17l14.63-7.122"></path>
                            </g>
                        </svg>
                    </span>
                </div>
                <h1>253</h1>
                <p>{{'Product.Pieces'|translate}}</p>
            </div>
            <div class="product-details text-justify" dir="auto">
                <div class="product-detailssvg pt-3 pb-3">
                    <svg width="37px" height="25px" aria-hidden="true" data-di-res-id="4b76a8eb-a938c954"
                        data-di-rand="1609076026408">
                        <path
                            d="M26.333 8.837L20.338 1h-3.669l-5.995 7.837L4.608 3.95H1v18.128L2.877 24l31.25-.03L36 22.048V3.95h-3.627l-6.04 4.888z"
                            stroke="#757575" stroke-width="2" fill="none" fill-rule="evenodd"></path>
                    </svg>

                </div>
                <h1>195</h1>
                <p>{{'Product.VIPPoints'|translate}}</p>
            </div>
            <div class="product-details text-justify" dir="auto" style="border-right:none ;">
                <div class="product-detailssvg pt-2 pb-3">
                    <svg width="36px" height="36px" aria-hidden="true" data-di-res-id="f3cacf8e-fce2d7d5"
                        data-di-rand="1609076026409">
                        <path
                            d="M13.81 12.333l-2.833 11.334h11.216l2.834-11.334H13.81zm.945-3.777H25.97l1.53-6.124a1.89 1.89 0 0 1 3.668.914l-1.303 5.21h3.245c1.047 0 1.889.846 1.889 1.889a1.887 1.887 0 0 1-1.889 1.888h-4.19l-2.833 11.334h7.023c1.047 0 1.889.846 1.889 1.888a1.887 1.887 0 0 1-1.889 1.89h-7.967l-1.534 6.123a1.888 1.888 0 1 1-3.66-.914l1.299-5.21H10.033l-1.534 6.124a1.888 1.888 0 1 1-3.66-.914l1.299-5.21h-3.25a1.89 1.89 0 0 1 0-3.777h4.194l2.834-11.334H2.889a1.89 1.89 0 0 1 0-3.777h7.971l1.534-6.124a1.884 1.884 0 0 1 2.285-1.375 1.883 1.883 0 0 1 1.375 2.29l-1.3 5.209z"
                            fill="#757575" stroke="#FFF" fill-rule="evenodd"></path>
                    </svg>

                </div>
                <h1>75967</h1>
                <p>{{'wishList.Items'|translate}}</p>
            </div>


        </div>

    </div>
</div> -->
<!--end-->

<!--
<div class="container-fluid text-justify" dir="auto">
    <div class="row rowselproduct" data-toggle="collapse" data-target="#Features" id="fet1">
        <div class="col-12 d-flex justify-content-between">
            <h3>{{'Product.Features'|translate}}</h3>
            <div id="pluswminus1" style="font-size: 20px;"><i class="fas fa-plus-circle"></i></div>
            <div id="pluswminus2" style="font-size: 20px; display: none;"><i class="fas fa-minus-circle"></i></div>
        </div>
        <div id="Features" class="container-fluid collapse">
            <div id="Feature1" class="mt-3">
                <picture>
                    <source media="(min-width:901px)" srcset="assets\images\75967-PDP-202006-Hero1-Standard-Large.jpeg">
                    <source media="(min-width:601px)"
                        srcset="assets\images\75967-PDP-202006-Hero1-Standard-Medium.jpeg">
                    <source media="(max-width:600px)" srcset="assets\images\75967-PDP-202006-Hero1-Standard-Small.jpeg">
                    <img src="assets\images\40416-HomePage-202011-Hero-Standard-Large.jpg" alt="Flowers"
                        style="width:auto;">
                </picture>
                <div id="Feature1content">
                    <h1 style="text-align: center;">{{'Product.Adventures'|translate}}</h1>
                    <span>{{'Product.imaginative'|translate}}</span>
                </div>
                <div id="Feature1content2">
                    <h6 class="font-weight-bold">{{'Product.Adventures'|translate}}</h6>
                    <span>{{'Product.imaginative'|translate}</span>
                </div>
            </div>
            <div id="Feature2">
                <picture>
                    <source media="(min-width:901px)" srcset="assets\images\75967-PDP-202006-Hero2-Standard-Large.jpeg">
                    <source media="(min-width:601px)"
                        srcset="assets\images\75967-PDP-202006-Hero2-Standard-Medium.jpeg">
                    <source media="(max-width:600px)" srcset="assets\images\75967-PDP-202006-Hero2-Standard-Small.jpeg">
                    <img src="assets\images\75967-PDP-202006-Hero2-Standard-Large.jpeg" alt="Flowers"
                        style="width:auto;">
                </picture>
                <div id="Feature2content">
                    <h1>{{'Product.Great'|translate}}</h1>
                    <span>{{'Product.awesome'|translate}}</span>
                </div>
                <div id="Feature2content2">
                    <h6 class="font-weight-bold">{{'Product.Great'|translate}}</h6>
                    <span>{{'Product.awesome'|translate}}</span>
                </div>
            </div>
            <h2 class="px-4 mb-3">{{'Product.Forbidden'|translate}} </h2>
            <div id="Feature3" class="row pt-5">
                <div class="col-sm-4 col-xs-12 mx-0">
                    <img src="assets\images\75967-PDP-202006-Block-Standard-1.jpeg">
                    <div class="py-2">
                        <h5 class="font-weight-bold">{{'Product.Hidden'|translate}}</h5>
                        <p>{{'Product.reveal'|translate}}</p>
                    </div>
                </div>
                <div class="col-sm-4 col-xs-12 mx-0">
                    <img src="assets\images\75967-PDP-202006-Block-Standard-2.jpeg">
                    <div class="py-2">
                        <h5 class="font-weight-bold">{{'Product.Poseable'|translate}}</h5>
                        <p>{{'Product.giant'|translate}}</p>
                    </div>
                </div>
                <div class="col-sm-4 col-xs-12 mx-0">
                    <img src="assets\images\75967-PDP-202006-Block-Standard-3.jpeg">
                    <div class="py-2">
                        <h5 class="font-weight-bold">{{'Product.characters'|translate}}</h5>
                        <p>{{'Product.With'|translate}}</p>
                    </div>
                </div>
            </div>
            <div id="Feature1" class="mt-3">
                <picture>
                    <source media="(min-width:901px)" srcset="assets\images\75967-PDP-202006-Hero3-Standard-Large.jpeg">
                    <source media="(min-width:601px)"
                        srcset="assets\images\75967-PDP-202006-Hero3-Standard-Medium.jpeg">
                    <source media="(max-width:600px)" srcset="assets\images\75967-PDP-202006-Hero3-Standard-Small.jpeg">
                    <img src="assets\images\40416-HomePage-202011-Hero-Standard-Large.jpg" alt="Flowers"
                        style="width:auto;">
                </picture>
                <div id="Feature1content">
                    <h1>{{'Product.Imaginations'|translate}}</h1>
                    <span>{{'Product.versatile'|translate}}</span>
                </div>
                <div id="Feature1content2">
                    <h6 class="font-weight-bold">{{'Product.Imaginations'|translate}}</h6>
                    <span>{{'Product.versatile'|translate}}</span>
                </div>
            </div>
            <div id="Feature1">
                <picture>
                    <source media="(min-width:901px)" srcset="assets\images\75967-PDP-202006-Hero4-Standard-Large.jpeg">
                    <source media="(min-width:601px)"
                        srcset="assets\images\75967-PDP-202006-Hero4-Standard-Medium.jpeg">
                    <source media="(max-width:600px)" srcset="assets\images\75967-PDP-202006-Hero4-Standard-Small.jpeg">
                    <img src="assets\images\40416-HomePage-202011-Hero-Standard-Large.jpg" alt="Flowers"
                        style="width:auto;">
                </picture>
                <div id="Feature1content">
                    <h1>{{'Product.GreatGift'|translate}}</h1>
                    <span>{{'Product.playset'|translate}}</span>
                </div>
                <div id="Feature1content2">
                    <h6 class="font-weight-bold">{{'Product.GreatGift'|translate}}</h6>
                    <span>{{'Product.playset'|translate}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
end-->

<!--
<div class="container-fluid text-justify" dir="auto">
    <div class="row rowselproduct" data-toggle="collapse" data-target="#Specifications" id="speci">
        <div class="col-12 d-flex justify-content-between">
            <h3>{{'Product.Specifications'|translate}}</h3>
            <div id="pluswminus3" style="font-size: 20px;"><i class="fas fa-plus-circle"></i></div>
            <div id="pluswminus4" style="font-size: 20px; display: none;"><i class="fas fa-minus-circle"></i></div>
        </div>
        <div id="Specifications" class="container-fluid collapse mt-3" style="background-color: white;">
            <div class="row">
                <div class="col-sm-7 col-xs-12">
                    <p>{{'Product.magical'|translate}}</p>
                    <p>{{'Product.Forest'|translate}}<br>
                        {{'Product.enter'|translate}}</p>
                    <p>{{'Product.Choosing'|translate}}<br>
                        {{'Product.looking'|translate}}</p>
                    <ul>
                        <li>{{'Product.li1'|translate}} </li>
                        <li>{{'Product.li2'|translate}}</li>
                        <li>{{'Product.li3'|translate}}</li>
                        <li>{{'Product.li4'|translate}}</li>
                        <li>{{'Product.li5'|translate}}</li>
                        <li>{{'Product.li6'|translate}}</li>
                        <li>{{'Product.li7'|translate}}</li>
                        <li>{{'Product.li8'|translate}}</li>
                        <li>{{'Product.li9'|translate}}</li>
                        <li>{{'Product.li10'|translate}}</li>
                    </ul>
                    <button type="button" class="btn btn-primary p-3">{{'Product.Instructions'|translate}}</button>
                </div>
                <div class="col-sm-5 d-none d-sm-block text-center">
                    <img src="assets\images\75967_alt1 (1).jpeg">
                </div>
            </div>
        </div>
    </div>
</div>
end-->




<!--start-->
<!-- <div class="container-fluid text-justify" dir="auto">
    <div class="row rowselproduct" data-toggle="collapse" data-target="#Specifications" id="speci">
        <div class="col-12 d-flex justify-content-between">
            <h3>{{'Product.Description'|translate}}</h3>
            <div id="pluswminus3" style="font-size: 20px;"><i class="fas fa-plus-circle"></i></div>
            <div id="pluswminus4" style="font-size: 20px; display: none;"><i class="fas fa-minus-circle"></i></div>
        </div>
        <div id="Specifications" class="container-fluid collapse mt-3" style="background-color: white;">
            <div class="container-fluid">
                <h4 *ngIf="translate.currentLang=='en'"> {{product?.description}}</h4>
                <h4 *ngIf="translate.currentLang=='ar'"> {{product?.arabicDescription}}</h4>
            </div>
        </div>
    </div>
</div> -->
<!--end-->
<!--start-->
<!-- <div class="container-fluid text-justify" dir="auto">
    <div class="row rowselproduct" data-toggle="collapse" data-target="#DeliveriesandReturns" id="del">
        <div class="col-12 d-flex justify-content-between">
            <h3>{{'Product.Deliveries'|translate}}</h3>
            <div id="pluswminus5" style="font-size: 20px;"><i class="fas fa-plus-circle"></i></div>
            <div id="pluswminus6" style="font-size: 20px; display: none;"><i class="fas fa-minus-circle"></i></div>
        </div>
        <div id="DeliveriesandReturns" class="container-fluid collapse" style="background-color: white;">
            <ul>
                <li>{{'Product.Free'|translate}}</li>
                <li>{{'Product.EXcheckout'|translate}}</li>
                <li>{{'Product.take'|translate}}</li>
                <li>{{'Product.Unopened'|translate}}</li>

            </ul>
            <p>{{'Product.Some'|translate}} <a href="javascript:void(0)">{{'Product.Deliveries'|translate}}</a>
                {{'Product.page'|translate}}
            </p>
        </div>
    </div>
</div> -->
<!--end-->



<!--start-->

<div class="container-fluid text-justify" dir="auto">
    <div class="row rowselproduct" id="cusrev" (click)="CustomerReviews()">
        <div class="col-12 d-flex justify-content-between">
            <h3>{{'Product.Customer'|translate}}</h3>
            <div id="pluswminus7" style="font-size: 20px;"><i class="fas fa-plus-circle"></i></div>
            <div id="pluswminus8" style="font-size: 20px; display: none;"><i class="fas fa-minus-circle"></i></div>
        </div>
    </div>
    <div id="CustomerReviews" class="container-fluid collapse my-2" style="background-color: white;">
        <!--hna-->
        <div class="mt-3 d-flex justify-content-between">
            <h3 class="font-weight-bold">{{'Product.Overall'|translate}}</h3>
            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#staticBackdrop">
                {{'WriteReview'|translate}}
            </button>
            <!-- Modal -->
            <div class="modal fade" id="staticBackdrop" data-backdrop="static" tabindex="-1" role="dialog"
                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">{{'Product.Customer'|translate}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">

                            <div class="row">

                                <div class="col-3">
                                    <img class="w-100 h-auto" src={{product?.image}}>
                                </div>
                                <div class="col-9">

                                    <h5 class="mb-5">{{'ProductRatings'|translate}}</h5>
                                    <h6 class="mb-2">{{'Product.Overall'|translate}}</h6>
                                    <div class="form-check">
                                        <input checked class="form-check-input" type="radio" name="Rate"
                                            id="exampleRadios1" value="1" (change)="radioforRatingChange($event)">
                                        <label class="form-check-label" for="exampleRadios1">
                                            {{'Poor'|translate}}
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="Rate" id="exampleRadios2"
                                            value="2" (change)="radioforRatingChange($event)">
                                        <label class="form-check-label" for="exampleRadios2">
                                            {{'Fair'|translate}}
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="Rate" id="exampleRadios3"
                                            value="3" (change)="radioforRatingChange($event)">
                                        <label class="form-check-label" for="exampleRadios3">
                                            {{'Avarge'|translate}}
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="Rate" id="exampleRadios4"
                                            value="4" (change)="radioforRatingChange($event)">
                                        <label class="form-check-label" for="exampleRadios4">
                                            {{'Good'|translate}}
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="Rate" id="exampleRadios5"
                                            value="5" (change)="radioforRatingChange($event)">
                                        <label class="form-check-label" for="exampleRadios5">
                                            {{'Excellent'|translate}}
                                        </label>
                                    </div>
                                    <h6 class="mt-3 mb-2">{{'Irecommendfriend'|translate}}</h6>
                                    <div class="form-check">
                                        <input checked class="form-check-input" type="radio" name="recommend"
                                            id="exampleRadios6" value="true" (change)="radioforRecommendChange($event)">
                                        <label class="form-check-label" for="exampleRadios6">
                                            {{'Yes'|translate}}
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="recommend"
                                            id="exampleRadios7" value="false"
                                            (change)="radioforRecommendChange($event)">
                                        <label class="form-check-label" for="exampleRadios7">
                                            {{'No'|translate}}
                                        </label>
                                    </div>



                                </div>
                            </div>
                            <form #revform="ngForm" >
                                <div class="container my-5">
                                    <h5>{{'AboutMe'|translate}}</h5>
                                    <div class="form-group">
                                        <label for="exampleInputtext1">{{'YourNickname'|translate}}</label>
                                        <input name="nickname" [(ngModel)]="nickname" type="text" class="form-control"
                                            id="exampleInputtext1" #name="ngModel" minlength="3" required>

                                        <div *ngIf="name.invalid && (name.dirty || name.touched)"
                                            class="alert alert-danger">

                                            <div *ngIf="name.errors.required">
                                                Name is required.
                                            </div>
                                            <div *ngIf="name.errors.minlength">
                                                Name must be at least 3 characters long.
                                            </div>

                                        </div>



                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">{{'User.address'|translate}}</label>
                                        <input name="email" [(ngModel)]="email" type="email" class="form-control"
                                            id="exampleInputEmail1" #useremail="ngModel" email="true" required>

                                        <div *ngIf="useremail.invalid && (useremail.dirty || useremail.touched)"
                                            class="alert alert-danger">

                                            <div *ngIf="useremail.invalid ">
                                                please enter a valid email
                                            </div>
                                            <div *ngIf="useremail.errors.required">
                                                email is required.
                                            </div>


                                        </div>
                                    </div>

                                </div>
                                <div class="container my-5">
                                    <h5>{{'DetailedReview'|translate}}</h5>
                                    <div class="form-group">
                                        <label for="exampleInputtext2">{{'ReviewTitle'|translate}}</label>
                                        <input name="reviewTitle" [(ngModel)]="reviewTitle" type="text"
                                            class="form-control" id="exampleInputtext2" #rev="ngModel" required
                                            minlength="4">
                                        <div *ngIf="rev.invalid && (rev.dirty || rev.touched)"
                                            class="alert alert-danger">

                                            <div *ngIf="rev.errors.required">
                                                review is required.
                                            </div>
                                            <div *ngIf="rev.errors.minlength">
                                                review must be at least 4 characters long.
                                            </div>

                                        </div>

                                    </div>
                                    <div class="form-group">
                                        <label for="exampleFormControlTextarea1">{{'Review'|translate}}</label>
                                        <textarea name="reviewBody" [(ngModel)]="reviewBody"
                                            class="form-control rounded-0" id="exampleFormControlTextarea1" rows="10"
                                            #revb="ngModel" required minlength="10"></textarea>
                                        <div *ngIf="revb.invalid && (revb.dirty || revb.touched)"
                                            class="alert alert-danger">

                                            <div *ngIf="revb.errors.required">
                                                review is required.
                                            </div>
                                            <div *ngIf="revb.errors.minlength">
                                                review body must be at least 10 characters long.
                                            </div>
                                        </div>





                                    </div>
                                    <button type="button" class="btn btn-primary"
                                        (click)="addReview()"
                                        [disabled]="revform.invalid">{{'Add'|translate}}
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- <div id="stars">
            <svg width="100%" height="100%" viewBox="0 0 50 49">
                <g stroke="none" stroke-width="1" fill="#FFD500">
                    <path
                        d="M49.9,18.1 C49.3,16.2 47.1,15.9 47.1,15.9 L34,13.9 L28,2 C28,2 27,0 25,0 C23,0 22,2 22,2 L16.1,13.9 L3,15.9 C3,15.9 0.8,16.2 0.2,18.1 C-0.4,20 1.2,21.6 1.2,21.6 L10.8,31 L8.3,44 C8.3,44 7.9,46.2 9.5,47.4 C11.1,48.6 13.1,47.6 13.1,47.6 L25,41.8 L37.1,47.7 C37.1,47.7 39.1,48.7 40.7,47.5 C42.3,46.3 41.9,44.1 41.9,44.1 L39.4,31.1 L49,21.7 C48.9,21.6 50.5,20 49.9,18.1 Z"
                        fill-rule="nonzero"></path>
                </g>
            </svg>
            <svg width="100%" height="100%" viewBox="0 0 50 49">
                <g stroke="none" stroke-width="1" fill="#FFD500">
                    <path
                        d="M49.9,18.1 C49.3,16.2 47.1,15.9 47.1,15.9 L34,13.9 L28,2 C28,2 27,0 25,0 C23,0 22,2 22,2 L16.1,13.9 L3,15.9 C3,15.9 0.8,16.2 0.2,18.1 C-0.4,20 1.2,21.6 1.2,21.6 L10.8,31 L8.3,44 C8.3,44 7.9,46.2 9.5,47.4 C11.1,48.6 13.1,47.6 13.1,47.6 L25,41.8 L37.1,47.7 C37.1,47.7 39.1,48.7 40.7,47.5 C42.3,46.3 41.9,44.1 41.9,44.1 L39.4,31.1 L49,21.7 C48.9,21.6 50.5,20 49.9,18.1 Z"
                        fill-rule="nonzero"></path>
                </g>
            </svg>
            <svg width="100%" height="100%" viewBox="0 0 50 49">
                <g stroke="none" stroke-width="1" fill="#FFD500">
                    <path
                        d="M49.9,18.1 C49.3,16.2 47.1,15.9 47.1,15.9 L34,13.9 L28,2 C28,2 27,0 25,0 C23,0 22,2 22,2 L16.1,13.9 L3,15.9 C3,15.9 0.8,16.2 0.2,18.1 C-0.4,20 1.2,21.6 1.2,21.6 L10.8,31 L8.3,44 C8.3,44 7.9,46.2 9.5,47.4 C11.1,48.6 13.1,47.6 13.1,47.6 L25,41.8 L37.1,47.7 C37.1,47.7 39.1,48.7 40.7,47.5 C42.3,46.3 41.9,44.1 41.9,44.1 L39.4,31.1 L49,21.7 C48.9,21.6 50.5,20 49.9,18.1 Z"
                        fill-rule="nonzero"></path>
                </g>
            </svg>
            <svg width="100%" height="100%" viewBox="0 0 50 49">
                <g stroke="none" stroke-width="1" fill="#FFD500">
                    <path
                        d="M49.9,18.1 C49.3,16.2 47.1,15.9 47.1,15.9 L34,13.9 L28,2 C28,2 27,0 25,0 C23,0 22,2 22,2 L16.1,13.9 L3,15.9 C3,15.9 0.8,16.2 0.2,18.1 C-0.4,20 1.2,21.6 1.2,21.6 L10.8,31 L8.3,44 C8.3,44 7.9,46.2 9.5,47.4 C11.1,48.6 13.1,47.6 13.1,47.6 L25,41.8 L37.1,47.7 C37.1,47.7 39.1,48.7 40.7,47.5 C42.3,46.3 41.9,44.1 41.9,44.1 L39.4,31.1 L49,21.7 C48.9,21.6 50.5,20 49.9,18.1 Z"
                        fill-rule="nonzero"></path>
                </g>
            </svg>
            <svg width="100%" height="100%" viewBox="0 0 50 49">
                <g stroke="none" stroke-width="1" fill="#CACACA">
                    <path
                        d="M49.9,18.1 C49.3,16.2 47.1,15.9 47.1,15.9 L34,13.9 L28,2 C28,2 27,0 25,0 C23,0 22,2 22,2 L16.1,13.9 L3,15.9 C3,15.9 0.8,16.2 0.2,18.1 C-0.4,20 1.2,21.6 1.2,21.6 L10.8,31 L8.3,44 C8.3,44 7.9,46.2 9.5,47.4 C11.1,48.6 13.1,47.6 13.1,47.6 L25,41.8 L37.1,47.7 C37.1,47.7 39.1,48.7 40.7,47.5 C42.3,46.3 41.9,44.1 41.9,44.1 L39.4,31.1 L49,21.7 C48.9,21.6 50.5,20 49.9,18.1 Z"
                        fill-rule="nonzero"></path>
                </g>
            </svg>
            <span class="mx-2 ">4.2</span>
            <span class="mx-2 ">({{reviewNumber}} Reviews)</span>
        </div> -->
        <i *ngFor="let i of [].constructor(overallRating())" class="fa fa-star text-warning" aria-hidden="true"></i>
        <span class="mx-2 ">({{reviewNumber}} {{'Product.Reviews'|translate}})</span>
        <p *ngIf="product?.rating>3" style="color: green;">{{'Product.HighRecommend'|translate}}</p>
        <p *ngIf="product?.rating<=3" style="color: orange;">{{'Product.MixedRecommend'|translate}}</p>
        <p *ngIf="product?.rating<2 && product?.rating>0" style="color: red;">{{'Product.LowRecommend'|translate}}
        </p>

        <div *ngFor="let item of reviews" class="my-3">
            <hr>
            <h3>{{item.reviewTitle}}</h3>
            <i *ngFor="let i of [].constructor(item.OverallRating)" class="fa fa-star text-warning"
                aria-hidden="true"></i>
            <h3 class="text-success" *ngIf="item.recommend">{{'Product.IRecommend'|translate}}</h3>
            <h3 class="text-danger" *ngIf="!item.recommend">{{'Product.INotRecommend'|translate}}</h3>
            <h4>{{item.review}}</h4>
        </div>
    </div>
</div>