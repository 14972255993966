<!-- Start Body Main -->
<div class="container-fluid" style="padding-left: 0;padding-right: 0;" dir="auto">
    <!--  -->
    <div class="container-fluid text-center header">
        <img src="assets/images/LEGOlifeLogo_compressed.png">
        <p><a href="javascript:void(0)" class="m-4">{{'LegoLife.App' | translate}}</a><a href="javascript:void(0)"
                class="m-4">{{'LegoLife.Magazine' | translate}}</a><a href="javascript:void(0)"
                class="m-4">{{'LegoLife.FAQ' | translate}}</a></p>
    </div>
    <div class="container-fluid"
        style="padding-left: 0;padding-right: 0;cursor: pointer;background: url('/../assets/images/magazin2.jfif');background-repeat: no-repeat;background-size: cover;">
        <div class="smallDivDis text-justify" dir="auto" style="width: 500px;padding: 50px 70px 50px 50px;">
            <h2 style="font-weight: 800;">{{'LegoLife.Life' | translate}}</h2>
            <p class="my-4" style="font-weight: bold;">{{'LegoLife.FreeLEGO' | translate}}</p>
            <button class="btnwhait py-2" data-toggle="modal" data-target="#exampleModalLong">{{'LegoLife.FindMore' |
                translate}}</button>
        </div>
    </div>
    <div class=" smallDivNone text-left" style="width: 500px;transform: scale(0.8);display: none;">
        <h2 style="font-weight: 400;">{{'LegoLife.Life' | translate}}</h2>
        <p class="my-4" style="font-weight: 400;">{{'LegoLife.FreeLEGO' | translate}}</p>
        <button class="btnwhait py-2" data-toggle="modal" data-target="#exampleModalLong">{{'LegoLife.FindMore' |
            translate}}</button>
    </div>
    <div class="container-fluid text-right " style="background: #2EBBCF;">
        <div class="scal" style="transform: scale(0.7);">
            <img src="assets/images/qute 1.JPG" style="position: relative; right: 95% !important;top: 0;height: 80px;">
            <h1 class="text-center mb-4" style="font-weight: 400 !important;font-size: 25px;padding-right: 100px;">
                {{'LegoLife.Mission' | translate}}</h1>
            <img src="assets/images/quta.JPG" style="height: 80px;">
        </div>
    </div>
    <div class="container-fluid row" style="padding-left: 0;padding-right: 0;cursor: pointer;">
        <img class="col-md-9" src="assets/images/app-hero-block.jfif" style="width: 100%;">
        <div class="S col-md-3 text-center my-4 py-4">
            <h2>{{'LegoLife.LifeApp' | translate}}</h2>
            <p class="my-4">{{'LegoLife.SafePlace' | translate}}</p>
            <button class="btnwhait py-2" data-toggle="modal" data-target="#exampleModalLong">{{'LegoLife.FindMore' |
                translate}}</button>
        </div>
    </div>
    <div class="container-fluid text-center dark">
        <div class="S" style="cursor: pointer;">
            <h1>{{'LegoLife.Got' | translate}}</h1>
            <p style="margin: 25px 0 25px 0;">{{'LegoLife.SignUp' | translate}}</p>
        </div>
        <button class="btn btnDark" data-toggle="modal" data-target="#exampleModalLong">{{'LegoLife.Find' |
            translate}}</button>
    </div>
    <div class="container-fluid row justify-content-center" style="background: #FBFBFB;">
        <div class="S text-center cenDIV col-sm-3 col-4">
            <img src="assets/images/cir1-girl-and-rainbow2.jfif" class="rounded-circle mb-3" alt="Cinque 1"
                height="160">
            <span style="display: block;">{{'LegoLife.App' | translate}}</span>
        </div>
        <div class="S text-center cenDIV col-sm-3 col-4 mx-4">
            <img src="assets/images/cir2-quick-links-magazine.jfif" class="rounded-circle mb-3" alt="Cinque 2"
                height="160">
            <span style="display: block;">{{'LegoLife.Magazine' | translate}}</span>
        </div>
        <div class="S text-center cenDIV col-sm-3 col-4">
            <img src="assets/images/cir3-legolife_quicklinks_faq.jfif" class="rounded-circle mb-3" alt="Cinque 3"
                height="160">
            <span style="display: block;">{{'LegoLife.FAQ' | translate}}</span>
        </div>
    </div>
</div>
<!-- End Body Main -->



<!-- Modal -->
<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{'LegoLife.Life' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{'modal1'|translate}}</p>
                <p>{{'modal2'|translate}}</p>
                <p>{{'modal3'|translate}}</p>
                <p>​{{'modal4'|translate}}</p>
                <p>{{'modal5'|translate}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'User.Close'|translate}}</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->