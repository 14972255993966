<div class="container"dir="auto">
  <div class="row">
    <!--Left Indentation-->
    <div class="col"></div>

    <!--Main-->
    <div class="col-lg-5 col-sm-12 text-center" id="main">

      <nav style="display: flex; justify-content: space-evenly; align-items: center;">
        
        <div>
          <img src="assets/images/newlogo.png" alt="logo" style="width:40px;">
        </div>

        <div>
          <span
            class="h1 text-dark font-weight-bold " id="navTxt"
            ><strong>{{'header.Account'|translate}}</strong>
          </span>
        </div>

        <div>
          <button
            type="button"
            class="close font-weight-bold text-right"
            id="navBtn"
            (click)="closeEdit()"
          >
            ×
          </button>
        </div>
      </nav>


      <h3 class="text-center" id="editTxt">
        {{'EditProfile'|translate}}
      </h3>
      <h3 class="text-center" id="avatarTxt">
        {{'Avatar'|translate}}
      </h3>

      <div id="demo" class="carousel slide" data-ride="carousel">
        <!-- The slideshow -->
        <div class="carousel-inner" data-interval="false">
          <div class="carousel-item active">
            <img
              src="assets\images\newlogo.png"
              alt="Icon1"
              class="imgEdit img-thumbnail rounded-circle"
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets\images\newlogo.png"
              alt="Icon2"
              class="imgEdit img-thumbnail rounded-circle"
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets\images\Register-Login-Images\Icons\lego (2).png"
              alt="Icon3"
              class="imgEdit img-thumbnail rounded-circle"
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets\images\Register-Login-Images\Icons\lego (3).png"
              alt="Icon4"
              class="imgEdit img-thumbnail rounded-circle"
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets\images\Register-Login-Images\Icons\lego (4).png"
              alt="Icon5"
              class="imgEdit img-thumbnail rounded-circle"
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets\images\Register-Login-Images\Icons\lego (5).png"
              alt="Icon6"
              class="imgEdit img-thumbnail rounded-circle"
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets\images\Register-Login-Images\Icons\legoo.png"
              alt="Icon7"
              class="imgEdit img-thumbnail rounded-circle"
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets\images\Register-Login-Images\Icons\pirate.png"
              alt="Icon8"
              class="imgEdit img-thumbnail rounded-circle"
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets\images\Register-Login-Images\Icons\spiderman.png"
              alt="Icon9"
              class="imgEdit img-thumbnail rounded-circle"
            />
          </div>
          <div class="carousel-item">
            <img
              src="assets\images\Register-Login-Images\Icons\spy.png"
              alt="Icon10"
              class="imgEdit img-thumbnail rounded-circle"
            />
          </div>
        </div>

        <!-- Left and right controls -->
        <a
          class="carousel-control-prev "
          href="#demo"
          data-slide="prev"
          style="color: black"
        >
          <span class="LRSpan"
            ><i
              class="fas fa-chevron-left bg-dark"
              class="LRIcons"
              style="
                font-size: 35px;
                color: black;
                margin-left: 100px;
              "
            ></i
          ></span>
        </a>
        <a
          class="carousel-control-next"
          href="#demo"
          data-slide="next"
        >
          <span class="LRSpan"
            ><i
              class="fas fa-chevron-right"
              class="LRIcons"
              style="
                font-size: 35px;
                color: black;
                margin-left: -190px;
              "
            ></i
          ></span>
        </a>
      </div>

      <br />

      <!--username-->
      <div class="container">
        <form #editUsernameForm="ngForm">
          <div class="mb-3 form-group">
            <label
              for="editUsernameTXT"
              id= "editUsernameLBL"
              class=" justify-content-between d-flex font-weight-bold text-justify" dir="auto"
              >{{'login.Username'|translate}}</label
            >
            <input
              #newEmailUsername="ngModel"
              [(ngModel)]="username"
              type="text"
              id="editUsernameTXT"
              class="form-control"
              name = "Username"
              required
            /><!--<i class="fas fa-pencil-alt"></i>-->
          </div>
        </form>
      </div>

      <!--btns-->
      <div class="container">
        <form >
          <div class="mb-3 form-group  text-justify" dir="auto" style="margin-left: 20px">
            <button
              class="form-control text-justify btnn"
              id="informationBtn"
              (click) = "EditInformation()"
            >
              <i
                class="far fa-square"
                id= "informationBtnIcon"
              ></i>
              {{'MyInformation'|translate}}
              <!-- <i class="fas fa-chevron-right m-auto" ></i> -->
            </button>

            <br />

            <button
              class="form-control text-justify btnn" dir="auto"
              id="securityBtn"
              (click) = "security()"
            >
              <i
                class="fas fa-shield-alt"
                id= "securityBtnIcon"
              ></i>
              {{'Security'|translate}}
              <!-- <i class="fas fa-chevron-right" style="margin-left: 240px"></i> -->
            </button>

            <br />
            
            <button
              id="logoutbtn"
              class="form-control text-justify btnn" dir="auto"
              (click) = "logout()"
            >
              <i
                class="fas fa-sign-out-alt"
                id="logoutBtnIcon"
              ></i>
              {{'Logout'|translate}}
              <!-- <i class="fas fa-chevron-right" style="margin-left: 245px"></i> -->
              <!-- <span class="text-right">
                
              ></span> -->
            </button>

          </div>
        </form>
      </div>

      <!--savebtn class="disabled"-->
      <button
        id="save"
        class="btn btn-primary h-25 w-25 border border-0 rounded"
        [disabled]="editUsernameForm.invalid"
        (click)="save()"
      >
      {{'Save'|translate}}
      </button>
    </div>

    <!--Right Indentation-->
    <div class="col"></div>
  </div>
</div>
