<div class="container-fluid">
  <div class="row text-justify" dir="auto">
    <div *ngIf="productsInBag==0" class="col-lg-8">
      <div class="d-flex align-items-center min-vh-100">
        <div class="container text-center">
          <p class="display-4">{{'Noproductsbag'|translate}}</p>
        </div>
    </div>
    </div>
    <div *ngIf="productsInBag!=0" class="col-lg-8">

      <span class="text-start">
        <h3>{{'MyBag.Cart'|translate}}</h3>
        <!-- <a href="Wish.html"></a> -->
        <p class="text-end">{{'MyBag.View'|translate}} </p>
      </span>
      <div class="jumbotron" style="background-color: #ffeacc ;padding:10px ;border: 1px #cfcfcf solid ">
        <p style="text-align: center">{{'MyBag.currently'|translate}}<br>{{'MyBag.currently2'|translate}}<br>
        </p>
      </div><br>
      <!--//////////////////////-->
      <div class="container">
        <div class="row">
          <div class="table-responsive">

            <div class="col-12">
              <table class="table table-image table-responsive">
                <thead>
                  <tr>
                    <th scope="col">{{'MyBag.Detail'|translate}}</th>
                    <th scope="col"></th>
                    <th scope="col" class="tab-space">{{'MyBag.Quantity'|translate}}</th>
                    <th scope="col"></th>
                    <th scope="col"></th>

                    <th scope="col">{{'wishList.Price'|translate}}</th>
                  </tr>
                </thead>
                <tbody>

                  <!--              //////////header of row 1 ///////////-->
                  <tr style="background-color: #bafff1">
                    <!--
                           
      -->
                    <th scope="row"></th>
                    <td class="w-25">
                      <span>{{'MyBag.qualified'|translate}} </span>
                    </td>
                    <td>
                      {{'MyBag.Holiday'|translate}}

                    </td>
                    <td>2020 </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <!--              ///////////////////-->
                  <!--              //////////////row 1 ///////////////////-->
                  <tr *ngFor="let prd of ProductList">
                    <th scope="row"><img src={{prd?.image}} style="width:30%;height: auto"></th>
                    <td class="w-25">
                      <small *ngIf="translate.currentLang=='en'"><b><br><br>{{prd?.name}}</b></small><br><br>
                      <small *ngIf="translate.currentLang=='ar'"><b><br><br>{{prd?.arabicName}}</b></small><br><br>
                      <!-- <small style="color: green">{{'Checkout.Available'|translate}}</small><br><br> -->
                      <!-- <small>Qty:3</small><br><br> -->
                      <!-- <small><b>{{prd?.price}}</b></small> -->
                    </td>
                    <!-- <td><br><br> -->
                      <!-- <div class="btn-group" role="group" aria-label="Basic outlined example">
                         <button type="button" class="btn btn-outline-primary">-</button>
                         <button type="button" class="btn btn-outline-primary">3</button>
                         <button type="button" class="btn btn-outline-primary">+</button>
                            </div>  -->

                    <!-- </td> -->
                    <td><br><br><small><b>{{'Checkout.QTY'|translate}}: {{prd?.theQty}}</b></small></td>
                    <td><br><br><i class="far fa-trash-alt" (click)="deleteProduct(prd?.id)"
                        style="font-size: 19px;color:#1f8bff"></i></td>
                    <td><br><br><i class="fa fa-eye" (click)="goToProduct(prd?.id)"
                        style="font-size: 19px;color:#1f8bff"></i></td>
                    <td><br><br><small><b>{{prd?.price | currency}}</b></small></td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!--  ///////////////////-->

    </div>
    <div class="col-lg-4">
      <br>
      <p class="fs-4">{{'Checkout.Order'|translate}}</p>
      <hr>
      <div class="accordion" id="accordionExample">
        <!--     //////////Accordion 1 start//////////-->

        <!-- <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button collapsed " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          <p class="fs-5">{{'MyBag.Promo'|translate}}</p>
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
          <div class="accordion-body">
                <div class="input-group mb-3">
             <input type="text" class="form-control" placeholder="Enter Code" aria-label="Recipient's username" aria-describedby="button-addon2">
            <button class="btn btn-outline-secondary" type="button" id="button-addon2">{{'MyBag.Apply'|translate}}</button>
            </div>
                
    
    
              </div>
              </div>
            </div> -->
        <!--     //////////Accordion 1 End//////////-->
        <!--     //////////////Accord2///////////////-->
        <!-- <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        <p class="fs-5">{{'MyBag.Shipping'|translate}}</p>
            </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse " aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div class="accordion-body">
          
        <div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
         <label class="form-check-label" for="flexRadioDefault1">
          <span style="  word-spacing: 195px;">{{'MyBag.tandardShipping'|translate}} </span> <br> <small>{{'MyBag.business'|translate}}3-5 </small>
            </label>
         </div><br>
              
        <div class="form-check">
         <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
         <label class="form-check-label" for="flexRadioDefault2">
          <span style="  word-spacing: 215px;">ُ{{'MyBag.ExpressSaver'|translate}} $18.95</span><br> <small>{{'MyBag.business'|translate}}2-3 </small>
          </label>
          </div><br>
              
        <div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3">
         <label class="form-check-label" for="flexRadioDefault3">
           <span style="  word-spacing: 250px;">{{'MyBag.Express'|translate}} $23.95</span><br> <small>{{'MyBag.business'|translate}}1-2 </small>
         </label>
         </div><br>
         
        </div>
            
        </div>
      </div> -->
        <!--         ///////////////Accord 2 end //////////////////////-->
      </div><br>
      <p class="fs-6"><b>{{'MyBag.estimate'|translate}}</b></p>
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Example:96321" aria-label="Recipient's username"
          aria-describedby="button-addon2">
        <button class="btn btn-outline-secondary" type="button" id="button-addon2">{{'MyBag.Apply'|translate}}</button>
      </div>
      <div class="text-justify" dir="auto">
        <div class="d-flex justify-content-between">
          <p>{{'MyBag.subtotal'|translate}}</p>
          <p>{{getTotal() | currency}}</p>
        </div>
        <div class="d-flex justify-content-between">
          <p>{{'MyBag.StandardShipping'|translate}}</p>
          <p>{{'MyBag.Free'|translate}}</p>
        </div>
        <div class="d-flex justify-content-between">
          <p>{{'TAX'|translate}}</p>
          <p>14%</p>
        </div>
        <div class="d-flex justify-content-between">
          <p>{{'Checkout.OrderTotal'|translate}}</p>
          <p>{{ (getTotal()+getTotal()*14/100) | currency }}</p>
        </div>
      </div>

      <div class="d-grid gap-2">
        <button [disabled]="productsInBag==0" (click)="goToCheckout()" class="btn btn-warning w-100" type="button"><i class="fas fa-lock"></i>
            {{'MyBag.Checkout'|translate}}</button>
      </div>
      <br>

      <div class="d-grid gap-2">
        <button [disabled]="productsInBag==0" class="btn btn-primary w-100" type="button"> {{'MyBag.PayWith'|translate}}
          <i><b>{{'MyBag.PayPall'|translate}}</b></i></button>
      </div>
      <br>
      <div style="background-color: aquamarine ;padding:10px">
        <p class="text-center">{{'MyBag.Congratulations'|translate}}</p>
      </div><br>

      <div class="bg-light">
        {{'MyBag.order'|translate}}<br><br>
        <p>{{'MyBag.Handling'|translate}} </p>
        <a>{{'MyBag.Returns'|translate}}</a><br><br>
        {{'MyBag.PaymentMethods'|translate}}<br>
        <span class="col-lg-5">

          <i class="fab fa-cc-mastercard" style="font-size: 50px"></i>
          <i class="fab fa-cc-visa" style="font-size: 50px"></i>
          <i class="fab fa-cc-discover" style="font-size: 50px"></i>
          <i class="fab fa-cc-amex" style="font-size: 50px"></i>
          <i class="fab fa-cc-paypal" style="font-size: 50px"></i>




        </span>
      </div>



    </div>
  </div>
</div>
<app-recommended-products></app-recommended-products>
<!-- <ngb-accordion #acc="ngbAccordion">
      <ngb-panel id="toggle-1" title="First panel">
        <ng-template ngbPanelContent>
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
          aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
          sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
          craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
          occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
          labore sustainable VHS.
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <button class="btn btn-sm btn-outline-primary mr-2" (click)="acc.toggle('toggle-1')">Toggle first</button> -->