<!--start-->
<div class="container-fluid" id="banner">
  <div class="row d-flex justify-content-center text-justify" dir="auto">
    <p>
      <img src="assets\images\40416-202011-Sitewide-Banner.png">
      <small>{{'Offers.Skating'|translate}}</small>
      <a href="javascript:void(0)">{{'VIP.shop'|translate}}</a>
    </p>

  </div>
</div>
<!--end-->

<!--start-->
<div class="container-fluid firstbar">
  <div class="row text-justify" dir="auto">
    <ul class="breadcrumb bg-white w-100 m-0">
      <li class="breadcrumb-item"><a href="javascript:void(0)">{{'VIP.Home'|translate}}</a></li>
      <li class="breadcrumb-item active">{{'Offers.OfferSale'|translate}}</li>
    </ul>
  </div>
</div>
<!--end-->

<!--start-->
<div class="container-fluid secondbar">
  <div class="row d-flex justify-content-center py-3">
    <button type="button" class="btn mx-2 rounded-pill"> <a href="javascript:void(0)">{{'Offers.Offers'|translate}}</a></button>
    <button type="button" class="btn mx-2 rounded-pill"><a href="javascript:void(0)">{{'Offers.Sales'|translate}}</a></button>
  </div>
</div>
<!--end-->

<!--start-->
<div class="container-fluid thirdbar">
  <div class="row d-flex justify-content-center py-4">
    <h1> {{'Offers.Featured'|translate}}</h1>
  </div>
</div>
<!--end-->


<!--start-->
<div class="container-fluid FREEIceSkatingRink px-0">
  <div class="row no-gutters">

    <div class="col-lg-6 col-md-6 col-sm-6  col-xs-12 FREEIceSkatingRink1">
      <img src="assets\images\tgropa.png">
    </div>

    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 FREEIceSkatingRink2 p-5 ">
      <h2 class="text-justify" dir="auto">{{'Offers.REE'|translate}}</h2>
      <span class="text-justify" dir="auto">{{'Offers.Discover'|translate}}*</span>
      <div>
        <button type="button" class="btn px-3 py-2 m-2 text-white " style="background-color: black;">
          <strong>{{'VIP.shop'|translate}}</strong>
          <svg width="16" height="16"
            class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
            viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
            <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
          </svg>
        </button>
      </div>
    </div>

  </div>
</div>
<!--end-->

<!--start-->
<div class="container-fluid thirdbar">
  <div class="row d-flex justify-content-center text-center py-4">
    <h1>{{'Offers.Check'|translate}} </h1>
  </div>
</div>
<!--end-->


<!--start-->
<div class="container-fluid FREEIceSkatingRink px-0">
  <div class="row no-gutters">

    <div class="col-lg-6 col-md-6 col-sm-6  col-xs-12 FREEIceSkatingRink1">
      <img src="assets\images\tgropa2.png">
    </div>

    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 FREEIceSkatingRink2 FREEIceSkatingRinkvip p-5 ">
      <h2 class="text-justify" dir="auto">{{'Offers.VIPOnly'|translate}}</h2>
      <span class="text-justify" dir="auto">{{'Offers.Wrap'|translate}}*</span>
      <div>
        <button type="button" class="btn px-3 py-2 m-2 " style="background-color: white; color: black;">
          <strong>{{'VIP.shop'|translate}}</strong>
          <svg width="16" height="16"
            class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
            viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
            <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
          </svg>
        </button>
      </div>
    </div>

  </div>
</div>
<!--end-->

<!--start-->
<div class="container-fluid Trending my-5">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6  col-xs-12 text-center">
      <img class="mb-3" src="assets\images\80015-VIP2X-202012-Block-Standard.jpeg">
      <h5 class="mb-3">{{'Offers.Monkie'|translate}}</h5>
      <p class="m-0">{{'Offers.Double'|translate}} 12/11-12/31.</p>
      <a href="javascript:void(0)">{{'VIP.shop'|translate}}
        <svg width="10" height="10"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </a>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6  col-xs-12 text-center">
      <img class="mb-3" src="assets\images\60261-VIP2X-202012-Block-Standard (1).jpeg">
      <h5 class="mb-3">{{'Offers.Central'|translate}} </h5>
      <p class="m-0">{{'Offers.Double'|translate}} 12/11-12/31.</p>
      <a href="javascript:void(0)">{{'VIP.shop'|translate}}
        <svg width="10" height="10"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </a>
    </div>
  </div>
</div>
<!--end-->

<!--start-->
<div class="container-fluid Trending my-5">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6  col-xs-12 text-center">
      <img class="mb-3" src="assets\images\60262-VIP2X-202012-Block-Standard.jpeg">
      <h5 class="mb-3">{{'Offers.Airplane'|translate}}</h5>
      <p class="m-0">{{'Offers.Double'|translate}} 12/1-12/31.</p>
      <a href="javascript:void(0)">{{'VIP.shop'|translate}}
        <svg width="10" height="10"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </a>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6  col-xs-12 text-center">
      <img class="mb-3" src="assets\images\10933-VIP2X-202012-Block-Standard.jpeg">
      <h5 class="mb-3">{{'Offers.Tower'|translate}}</h5>
      <p class="m-0">{{'Offers.Double'|translate}}  12/1-12/31.</p>
      <a href="javascript:void(0)">{{'VIP.shop'|translate}}
        <svg width="10" height="10"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </a>
    </div>
  </div>
</div>
<!--end-->


<!--start-->
<div class="container-fluid p-0 section10">
  <picture>
    <source media="(min-width:1201px)" srcset="assets\images\VIP-GA-202004-Hero-Short-Large.jpeg" style="width:100%">
    <source media="(min-width:601px)" srcset="assets\images\VIP-GA-202004-Hero-Short-Medium.jpeg" style="width:100%">
    <source media="(max-width:600px)" srcset="assets\images\VIP-GA-202004-Hero-Short-Small.jpeg" style="width:100%">
    <img src="assets\images\VIP-GA-202004-Hero-Short-Large.jpeg" style="width:100%">
  </picture>

  <div class="section10content">
    <span class="text-justify" dir="auto">{{'Offers.Join'|translate}}</span>
    <div>
      <button type="button" class="btn px-3 py-2 m-2 " style="background-color: white; color: black;">
        <strong>{{'VIP.shop'|translate}}</strong>
        <svg width="16" height="16"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </button>
    </div>
  </div>
  <div class="section10content2 p-3">
    <span class="text-justify" dir="auto">{{'Offers.Join'|translate}}</span>
    <div>
      <button type="button" class="btn px-3 py-2 m-2 " style="background-color: white; color: black;">
        <strong>{{'VIP.shop'|translate}}</strong>
        <svg width="16" height="16"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </button>
    </div>
  </div>


</div>
<!--end-->

<!--start-->
<div class="container-fluid thirdbar">
  <div class="row d-flex justify-content-center text-center py-4">
    <h1>{{'Offers.SaleNow'|translate}}</h1>
  </div>
</div>
<!--end-->

<!--start-->
<div class="container-fluid p-0 section11">
  <picture>
    <source media="(min-width:1201px)" srcset="assets\images\Sale-2020-Hero-Short-Large.jpeg" style="width:100%">
    <source media="(min-width:601px)" srcset="assets\images\Sale-2020-Hero-Short-Medium.jpeg" style="width:100%">
    <source media="(max-width:600px)" srcset="assets\images\Sale-2020-Hero-Short-Small.jpeg" style="width:100%">
    <img src="assets\images\Sale-2020-Hero-Short-Large.jpeg" style="width:100%">
  </picture>

  <div class="section11content">
    <span class="text-justify" dir="auto">{{'Offers.current'|translate}}</span>
    <div>
      <button type="button" class="btn px-3 py-2 m-2 " style="background-color: white; color: black;">
        <strong>{{'VIP.shop'|translate}}</strong>
        <svg width="16" height="16"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </button>
    </div>
  </div>
  <div class="section11content2 p-3">
    <span class="text-justify" dir="auto">{{'Offers.current'|translate}}</span>
    <div>
      <button type="button" class="btn px-3 py-2 m-2 " style="background-color: white; color: black;">
        <strong>{{'VIP.shop'|translate}}</strong>
        <svg width="16" height="16"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </button>
    </div>
  </div>


</div>
<!--end-->

<!--start-->
<div class="container-fluid thirdbar">
  <div class="row d-flex justify-content-center text-center py-4">
    <h1>{{'Offers.Promotions'|translate}}</h1>
  </div>
</div>
<!--end-->

<!--start-->
<div class="container-fluid FREEIceSkatingRink px-0">
  <div class="row no-gutters">

    <div class="col-lg-6 col-md-6 col-sm-6  col-xs-12 FREEIceSkatingRink1">
      <img src="assets\images\sortatr.png">
    </div>

    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 FREEIceSkatingRink2 p-5 instorepromotion">
      <h2 class="text-justify" dir="auto">{{'Offers.Train'|translate}}</h2>
      <span class="text-justify" dir="auto">Y{{'Offers.fans'|translate}}*</span>
      <div>
        <button type="button" class="btn px-3 py-2 m-2 text-white " style="background-color: black;">
          {{'header.FindStore'|translate}}
          <svg width="16" height="16"
            class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
            viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
            <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
          </svg>
        </button>
      </div>
    </div>

  </div>
</div>
<!--end-->
<!--start-->
<div class="container-fluid FREEIceSkatingRink px-0">
  <div class="row no-gutters">

    <div class="col-lg-6 col-md-6 col-sm-6  col-xs-12 FREEIceSkatingRink1">
      <img src="assets\images\sortatr2.png">
    </div>

    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 FREEIceSkatingRink2 p-5 instorepromotion2">
      <h2 class="text-justify" dir="auto">{{'Offers.Traveling'|translate}}</h2>
      <span class="text-justify" dir="auto">{{'Offers.gift'|translate}}*</span>
      <div>
        <button type="button" class="btn px-3 py-2 m-2 text-white " style="background-color: black;">
          {{'header.FindStore'|translate}}
          <svg width="16" height="16"
            class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
            viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
            <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
          </svg>
        </button>
      </div>
    </div>

  </div>
</div>
<!--end-->
<!--start-->
<div class="container-fluid FREEIceSkatingRink px-0">
  <div class="row no-gutters">



    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 FREEIceSkatingRink2 p-5 instorepromotion3">
      <h2 class="text-justify" dir="auto">{{'Offers.InStore'|translate}}</h2>
      <span class="text-justify" dir="auto">{{'Offers.Collect'|translate}}*</span>
      <div>
        <button type="button" class="btn px-3 py-2 m-2 text-white " style="background-color: black;">
          {{'header.FindStore'|translate}}
          <svg width="16" height="16"
            class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
            viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
            <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
          </svg>
        </button>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6  col-xs-12 FREEIceSkatingRink1">
      <img src="assets\images\sortatr3.png">
    </div>

  </div>
</div>
<!--end-->

<!--start-->
<div class="container-fluid Trending my-5">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6  col-xs-12 text-center">
      <img class="mb-3" src="assets\images\life-magazine-2019-block.jpeg">
      <h5 class="mb-3">{{'Offers.Magazine'|translate}}</h5>
      <p class="m-0">{{'Offers.activities'|translate}}</p>
      <a href="javascript:void(0)">{{'wishList.SignUp'|translate}}
        <svg width="10" height="10"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </a>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6  col-xs-12 text-center">
      <img class="mb-3" src="assets\images\SMS-General-Promo-Block-Standard.jpeg">
      <h5 class="mb-3">{{'Offers.texts'|translate}}</h5>
      <p class="m-0">{{'Offers.CheckOut'|translate}}</p>
      <a href="javascript:void(0)">{{'VIP.more'|translate}}
        <svg width="10" height="10"
          class="Chevron__ChevronIcon-sc-1q2x5f4-0 ljcWvn LinkWithChevron__StyledChevron-s8q0kp-1 dPpLSd"
          viewBox="0 0 18 28" aria-hidden="true" data-di-res-id="f01b8605-944e899d" data-di-rand="1608420761213">
          <path d="M1.825 28L18 14 1.825 0 0 1.715 14.196 14 0 26.285z" fill="currentColor"></path>
        </svg>
      </a>
    </div>
  </div>
</div>
<!--end-->

<!--start-->
<div class="container-fluid thirdbar">
  <div class="row d-flex justify-content-center text-center py-4">
    <h1>*{{'VIP.Terms'|translate}}</h1>
  </div>
</div>
<!--end-->

<!--start-->
<div class="container-fluid">
  <div class="row px-3 py-4 text-justify" dir="auto">
    <p>{{'Offers.Points'|translate}} 60262 and 10933 {{'Offers.only'|translate}}.</p>
    <span class="text-justify" dir="auto">{{'Offers.online'|translate}}</span>
  </div>
  <div class="row px-3 py-4 text-justify" dir="auto">
    <p>{{'Offers.Points'|translate}} 80015 and 60261 {{'Offers.only'|translate}}.</p>
    <span class="text-justify" dir="auto">{{'Offers.online'|translate}}</span>
  </div>

  <div class="row px-3 py-4">
    <span class="text-justify" dir="auto">{{'Offers.Exclusive'|translate}}</span>
  </div>

  <div class="row px-3 py-4">
    <span class="text-justify" dir="auto">{{'Offers.Holiday'|translate}}</span>
  </div>

  <div class="row px-3 py-4">
    <span class="text-justify" dir="auto">{{'Offers.Sleigh'|translate}}</span>
  </div>

  <div class="row px-3 py-4">
    <span class="text-justify" dir="auto">{{'Offers.Stores'|translate}}</span>
  </div>

  <div class="row px-3 py-4">
    <span class="text-justify" dir="auto">{{'Offers.purchases'|translate}}</span>
  </div>

  <div class="row px-3 py-4">
    <span class="text-justify" dir="auto"> <strong>{{'Offers.Every'|translate}}</strong> {{'Offers.applies'|translate}}</span>
  </div>
</div>