<div class="container" dir="auto">
  <div class="row">
    <!--Left Indentation-->
    <div class="col"></div>

    <!--Main-->
    <div class="col-lg-5 col-sm-12 text-center" id="main">
      <!--Top yellow bar-->
      <nav style="display: flex; justify-content: space-evenly; align-items: center;">
        <div>
          <a role="button"
              ><i
                class="fas fa-chevron-left"
                id="backBtn"
                (click)="goBackBtn()"
              ></i
            ></a>
        </div>

        <!-- <div>
          <img src="assets/images/newlogo.png" alt="logo" style="width:40px;">
        </div> -->

        <div>
          <span
            class="h1 text-dark font-weight-bold " id="navTxt"
            ><strong>{{ "User.Account" | translate }}</strong>
          </span>
        </div>

        <div>
          <button
            type="button"
            class="close font-weight-bold text-right"
            id="navBtn"
            (click)="closeBtn()"
          >
            ×
          </button>
        </div>

      </nav>

      <!--Main Body-->
      <div>
        <h5 id="info-text">{{ "User.Create" | translate }}</h5>

        <p id="info-text2">{{ "User.Already" | translate }}</p>

        <button
          id="regPageLoginBtn"
          type="submit"
          class="btn"
          [routerLink]="['/Login']"
        >
          {{ "User.Log" | translate }}
        </button>

        <!--<div class="d-flex" style="margin-bottom: 15px">
          <button
            id="continuewfbBtn"
            class="text-center justify-content-center d-flex"
          >
            <i id="continuewfbIcon" class="fab fa-facebook-square"></i> {{'User.Facebook'|translate}}
          </button>
          <img
            src="assets/images/Register-Login-Images/search.png"
            id="searchIcon"
          />
          <img
            src="assets/images/Register-Login-Images/apple.png"
            id="appleIcon"
          />
        </div>-->

        <div class="d-flex">
          <hr class="line" />
          <p id="useEmailParagraph">{{ "User.email" | translate }}</p>
          <hr class="line" />
        </div>

        <img
          src="assets/images/newlogo.png"
          style="height: auto"
          class="img-fluid"
        />

        <!--Sign up form-->

        <div>
          <form #regForm="ngForm" id="signupForm" (ngSubmit)="addnewCustomer()">
            <!--email-->
            <div class="mb-3 from-group">
              <label
                id="regEmailLabel"
                for="emailRegTxt"
                class="form-label text-left justify-content-left d-flex"
                >{{ "User.address" | translate }}</label
              >
              <input
                #newEmail="ngModel"
                [(ngModel)]="email"
                name="email"
                type="text"
                class="form-control"
                id="emailRegTxt"
                placeholder="example@domain.com"
                required
              />
              <div
                class="text-danger text-left emailWarn"
                *ngIf="!newEmail.valid && newEmail.touched"
              >
                {{ "User.EmailRequired" | translate }}
              </div>
            </div>

            <!--password-->
            <div class="mb-3 from-group">
              <label
                id="regPasswordLabel"
                for="passwordRegTxt"
                class="form-label text-left justify-content-left d-flex"
                >{{ "User.Password" | translate }}</label
              >
              <input
                #newPassword="ngModel"
                [(ngModel)]="password"
                name="password"
                type="password"
                class="form-control"
                id="passwordRegTxt"
                placeholder="* * * * * * * *"
                required
                min="10"
              />
              <div
                class="text-danger text-left passWarn"
                *ngIf="!newPassword.valid && newPassword.touched"
              >
                {{ "User.MustPass" | translate }}
                <br />
                {{ "User.PasswordRequired" | translate }}
              </div>
            </div>

            <!--birthday-->
            <div class="mb-3 from-group">
              <label
                id="regBDLabel"
                for="birthdateTxt"
                class="form-label text-left justify-content-left d-flex"
                >{{ "User.Birthdate" | translate }}</label
              >
              <div id="birthdateTxt" class="d-flex">
                <!--month-->
                <div class="mb-3">
                  <label
                    id="regmonthLabel"
                    for="monthDateTxt"
                    class="form-label text-left justify-content-left d-flex"
                    >{{ "User.Month" | translate }}</label
                  >
                  <input
                    #newBDMonth="ngModel"
                    [(ngModel)]="month"
                    name="month"
                    type="text"
                    class="form-control"
                    id="monthDateTxt"
                    placeholder="MM"
                    required
                  />
                </div>

                <!--day-->
                <div class="mb-3">
                  <label
                    id="regDayLabel"
                    for="dayDateTxt"
                    class="form-label text-left justify-content-left d-flex"
                    >{{ "User.Day" | translate }}</label
                  >
                  <input
                    #newBDDay="ngModel"
                    [(ngModel)]="day"
                    name="day"
                    type="text"
                    class="form-control"
                    id="dayDateTxt"
                    placeholder="DD"
                    required
                  />
                </div>

                <!--year-->
                <div class="mb-3">
                  <label
                    id="regYearLabel"
                    for="yearDateTxt"
                    class="form-label text-left justify-content-left d-flex"
                    >{{ "User.Year" | translate }}</label
                  >
                  <input
                    #newBDYear="ngModel"
                    [(ngModel)]="year"
                    name="year"
                    type="text"
                    class="form-control"
                    id="yearDateTxt"
                    placeholder="YYYY"
                    required
                  />
                </div>
                <br />
              </div>
              <div
                class="text-danger text-left BDWarn"
                *ngIf="
                  !newBDMonth.valid ||
                  !newBDDay.valid ||
                  (!newBDYear.valid &&
                    newBDMonth.touched &&
                    newBDDay.touched &&
                    newBDYear.touched)
                "
              >
                <div
                  class="text-danger text-left BDWarn"
                  [hidden]="
                    newBDMonth.valid && newBDDay.valid && newBDYear.valid
                  "
                >
                  {{ "User.DateRequired" | translate }}
                </div>
              </div>
            </div>

            <!--Country-->
            <div class="mb-3 from-group">
              <label
                id="regCountryLabel"
                for="countryRegTxt"
                class="form-label text-left justify-content-left d-flex"
                >{{ "User.Country" | translate }}</label
              >
              <select
                id="countryRegTxt"
                class="form-control"
                placeholder="Country"
              >
                <option value="Afganistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire">Bonaire</option>
                <option value="Bosnia & Herzegovina">
                  Bosnia & Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Ter">
                  British Indian Ocean Ter
                </option>
                <option value="Brunei">Brunei</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Canary Islands">Canary Islands</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Channel Islands">Channel Islands</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos Island">Cocos Island</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote DIvoire">Cote DIvoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curaco">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="East Timor">East Timor</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands">Falkland Islands</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Ter">French Southern Ter</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Great Britain">Great Britain</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="Indonesia">Indonesia</option>
                <option value="India">India</option>
                <option value="Iran">Iran</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea North">Korea North</option>
                <option value="Korea Sout">Korea South</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Laos">Laos</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libya">Libya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macau">Macau</option>
                <option value="Macedonia">Macedonia</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Malawi">Malawi</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Midway Islands">Midway Islands</option>
                <option value="Moldova">Moldova</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Nambia">Nambia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherland Antilles">Netherland Antilles</option>
                <option value="Netherlands">
                  Netherlands (Holland, Europe)
                </option>
                <option value="Nevis">Nevis</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau Island">Palau Island</option>
                <option value="Palestine">Palestine</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Phillipines">Philippines</option>
                <option value="Pitcairn Island">Pitcairn Island</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Republic of Montenegro">
                  Republic of Montenegro
                </option>
                <option value="Republic of Serbia">Republic of Serbia</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="Rwanda">Rwanda</option>
                <option value="St Barthelemy">St Barthelemy</option>
                <option value="St Eustatius">St Eustatius</option>
                <option value="St Helena">St Helena</option>
                <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                <option value="St Lucia">St Lucia</option>
                <option value="St Maarten">St Maarten</option>
                <option value="St Pierre & Miquelon">
                  St Pierre & Miquelon
                </option>
                <option value="St Vincent & Grenadines">
                  St Vincent & Grenadines
                </option>
                <option value="Saipan">Saipan</option>
                <option value="Samoa">Samoa</option>
                <option value="Samoa American">Samoa American</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syria">Syria</option>
                <option value="Tahiti">Tahiti</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania">Tanzania</option>
                <option value="Thailand">Thailand</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Erimates">
                  United Arab Emirates
                </option>
                <option value="United States of America">
                  United States of America
                </option>
                <option value="Uraguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Vatican City State">Vatican City State</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Vietnam">Vietnam</option>
                <option value="Virgin Islands (Brit)">
                  Virgin Islands (Brit)
                </option>
                <option value="Virgin Islands (USA)">
                  Virgin Islands (USA)
                </option>
                <option value="Wake Island">Wake Island</option>
                <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                <option value="Yemen">Yemen</option>
                <option value="Zaire">Zaire</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
            </div>

            <!--Terms abd Conditions check box-->
            <div class="mb-3 from-group form-check">
              <div class="d-flex">
                <input
                  #newTermsandConditionsCheck="ngModel"
                  [(ngModel)]="termsandconditionsCheck"
                  name="check"
                  type="checkbox"
                  class="form-check-input justify-content-left d-flex"
                  id="acceptTermsCheck"
                />
                <a
                  href="javascript:void(0)"
                  id="regTermsandConditionsLabel"
                  class="form-check-label d-flex text-left justify-content-left"
                  for="acceptTermsCheck"
                  >{{ "User.Accept" | translate }}</a
                >
              </div>
              <p id="regModalLabel" class="text-left">
                {{ "User.Agree" | translate }}
                <a href="#" data-toggle="modal" data-target="#myModal">{{
                  "User.TermsConditions" | translate
                }}</a>
                {{ "User.consent" | translate }}
              </p>
              <div
                class="text-danger text-left TermsandConditionsWarn"
                [hidden]="newTermsandConditionsCheck.valid == true"
              >
                {{ "User.Conditions" | translate }}
              </div>
            </div>

            <button
              type="submit"
              [disabled]="regForm.invalid"
              class="btn Confirmbtn"
            >
              {{ "User.Confirm" | translate }}
            </button>
          </form>
        </div>
      </div>

      <!--Terms and Conditions Modal-->
      <div class="modal" id="myModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- Modal Header 
                <div class="modal-header">
                  <h1 class="modal-title">Terms and conditions</h1>
                  <button type="button" class="close" data-dismiss="modal">×</button>
                </div>-->

            <!-- Modal body -->
            <div class="modal-body">
              <h5 id="regModalHeaderTxt">
                <b>{{ "User.TermsConditions" | translate }}</b>
              </h5>
              <div
                class="text-left justify-content-left"
                style="font-size: 14px"
              >
                <p class="modalBoldTxt">
                  <b
                    >{{ "User.Terms" | translate }}<br />{{
                      "User.stuff" | translate
                    }}<br />{{ "User.WhoWe" | translate }}</b
                  >
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.company" | translate }}
                  <a href="#"> {{ "User.here" | translate }}</a
                  >.
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.seeing" | translate }}</b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.legal" | translate }}<br /><br />
                  {{ "User.whichAccount" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.WhatDo" | translate }}</b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.termsApply" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.olderUser" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.permission" | translate }}
                  <br /><br />
                  {{ "User.rights" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b
                    >{{ "User.Consenting" | translate }}<br />
                    {{ "User.relation" | translate }}
                  </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.parentLegal" | translate }} <br /><br />
                  {{ "User.acceptedOur" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.consentingParent" | translate }}</b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.PartConsent" | translate }} <br /><br />

                  {{ "User.yourChild" | translate }} <br /><br />

                  {{ "User.privacy" | translate }} <br /><br />

                  {{ "User.willAlso" | translate }}
                  <br /><br />

                  {{ "User.approach" | translate }} <br />

                  <b>{{ "User.Services" | translate }}</b>
                </p>

                <p class="modalBoldTxt">
                  <b
                    >{{ "User.access" | translate }}
                    <br />
                    {{ "User.relation" | translate }}
                  </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.Besides" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b
                    >{{ "User.Linked" | translate }} <br />
                    {{ "User.relation" | translate }}
                  </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.All" | translate }}<br /><br />

                  {{ "User.specifically" | translate }}
                  <br />
                  {{ "User.creative" | translate }} <br />
                  {{ "User.SharePhotos" | translate }} <br />
                  {{ "User.social" | translate }} <br />

                  {{ "User.friends" | translate }} <br />
                  {{ "User.personalized" | translate }}<br /><br />

                  {{ "User.willBeAble" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b
                    >{{ "User.Services2" | translate }}<br />
                    {{ "User.relation" | translate }}
                  </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.policies" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.Safety" | translate }}</b>
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.understand" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.Experiences" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.moderate" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.channels" | translate }} <br /><br />
                  {{ "User.Certain" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b
                    >{{ "User.Advertisements" | translate }}<br />
                    {{ "User.ServeAdvertisements" | translate }}</b
                  >
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.willOnly" | translate }} <br /><br />

                  {{ "User.providing" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b
                    >{{ "User.IP" | translate }}<br /><br />
                    {{ "User.privacyRights" | translate }}
                  </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.ToOperate" | translate }}<br /><br />

                  {{ "User.weProcess" | translate }} <br /><br />

                  {{ "User.personalInformation" | translate }} <br /><br />

                  {{ "User.PrivacyPolicy" | translate }} <br /><br />

                  {{ "User.collecting" | translate }}
                  <br /><br />

                  {{ "User.verifiableParental" | translate }}<br /><br />

                  {{ "User.ourLegal" | translate }} <br /><br />

                  {{ "User.seeMore" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.aboutYour" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.LEGOEarned" | translate }} <br /><br />

                  {{ "User.AlsoPost" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.AboutOur" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.picturesText" | translate }}
                  <br /><br />

                  {{ "User.giveYou" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.obligations" | translate }}</b>
                </p>
                <br />
                <p class="modalBoldTxt">
                  <b>{{ "User.ourObligations" | translate }}</b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.courtOrders" | translate }}<br /><br />

                  {{ "User.guarantee" | translate }} <br /><br />

                  {{ "User.entertainment" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.YourObligat" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.otherwise" | translate }} <br /><br />

                  {{ "User.contactUus" | translate }} <br /><br />

                  {{ "User.Agreechild’s" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.Termination" | translate }}</b>
                </p>
                <br />
                <p class="modalBoldTxt">
                  <b>{{ "User.WhenTerminate" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.stopUsing" | translate }}
                  <a href="#">{{ "User.here" | translate }}</a
                  >. <br /><br />

                  {{ "User.VIPprogram" | translate }} <br /><br />

                  {{ "User.childTurns" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.WhenTerminate" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.createRisk" | translate }} <br /><br />

                  {{ "User.suspendAccess" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.Deletion" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.deleteYour" | translate }}
                  <br /><br />

                  {{ "User.informationRelating" | translate }} <br /><br />

                  {{ "User.Furthermore" | translate }} <br /><br />

                  {{ "User.aboutDeletion" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.ChangServices" | translate }}</b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.improving" | translate }} <br /><br />

                  {{ "User.Occasionally" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.update" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.legalGuardian" | translate }} <br /><br />

                  {{ "User.contactingYou" | translate }} <br /><br />

                  {{ "User.legalRequirements" | translate }} <br /><br />

                  {{ "User.announced" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.ContactingUs" | translate }}</b>
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.questions" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.feedback" | translate }}
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.observedA" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.encounteredVia" | translate }}
                  <a href="https://www.LEGO.com/service"
                    >https://www.LEGO.com/service</a
                  >.
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.complain" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.complainAbout" | translate }}
                  <a href="https://www.LEGO.com/service"
                    >https://www.LEGO.com/service</a
                  >.
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.OtherInformation" | translate }} </b>
                </p>

                <p class="modalBoldTxt">
                  <b>{{ "User.jurisdiction" | translate }} </b>
                </p>
                <p class="modalDetailsTxt">
                  {{ "User.submitTo" | translate }}
                </p>
              </div>

              <button
                id="modalCloseBtn"
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
              >
                {{ "User.Close" | translate }}
              </button>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Right Indentation-->
    <div class="col"></div>
  </div>
</div>
