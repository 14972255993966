<div class="container" dir="auto">
  <div class="row">
    <div class="col"></div>

    <div class="col-5 text-center" id="main">
      <nav class="navbar justify-content-center d-flex flex-fill">
        <div class="container-fluid text-nowrap text-justify" dir="auto">
          <a href="javascript:void(0)" role="button"
            ><i class="fas fa-chevron-left" id="backBtn"></i
          ></a>
          <!-- <img id="navImg" src="assets/images/newlogo.png" /> -->
          <span
            class="navbar-brand mb-0 h1 justify-content-left text-justify" dir="auto"
            id="navTxt"
            ><strong>{{'RecoverPass.Account'|translate}}</strong></span
          >
        </div>
      </nav>
      <br />
      <div class="container-fluid">
        <div>
          <h4 class="text-center"dir="auto">{{'RecoverPass.Recover'|translate}}</h4>
          <br />
          <p class="text-center">
            {{'RecoverPass.registered'|translate}}
          </p>

          <form id="forgotUsername">
            <div class="mb-3">
              <label
                id="forgotUsernameLbl"
                for="ForgotUsernameTxt"
                class="form-label text-justify justify-content-left d-flex" dir="auto"
                >{{'RecoverPass.Username'|translate}}</label
              >
              <input
                #forgotemail ="ngModel"
                [(ngModel)]="forgotppass"
                name="txt"
                type="text"
                class="form-control"
                id="ForgotUsernameTxt"
                placeholder="Your username"
                required
              />
              <div
                class="text-danger text-justify emailWarn" dir="auto"
                [hidden]="forgotemail.valid"
              >
              {{'RecoverPass.field'|translate}}
              </div>
            </div>
            <button
              type="submit"
              id="recoverUsernameBtn"
              class="btn btn-primary"
              (click) = "resetPass()"
            >
            {{'RecoverPass.Submit'|translate}}
            </button>
            <a
              class="nav-link text-primary font-weight-bold"
              [routerLink]="['/Login']"
            >
              {{'RecoverPass.Back'|translate}}
            </a>
          </form>
        </div>
      </div>
      <br />
      <br />
    </div>

    <div class="col"></div>
  </div>
</div>
