<div class="container">
    <div class="row">
        <!--Left Indentation-->
        <div class="col">
        </div>

        <!--Main-->
        <div class="col-lg-5 col-sm-12 text-center" id="main" >
          <!--Navigation Bar-->

          <nav style="display: flex; justify-content: space-evenly; align-items: center;">
            <div>
              <a role="button"
                  ><i
                    class="fas fa-chevron-left"
                    id="backBtn"
                    (click)="goBack()"
                  ></i
                ></a>
            </div>

            <div>
              <img src="assets/images/newlogo.png" alt="logo" style="width:40px;">
            </div>

            <div>
              <span
                class="h1 text-dark font-weight-bold " id="navTxt"
                ><strong>{{'header.Account'|translate}}</strong>
              </span>
            </div>

            <div>
              <button
                type="button"
                class="close font-weight-bold text-right"
                id="navBtn"
                (click)="closeBtn()"
              >
                ×
              </button>
            </div>

          </nav>


          <h4 class="text-center mt-4" style="margin-bottom: 20px; font-size: 20px;">{{'Security'|translate}}</h4>

          <div class="d-flex  text-center justify-content-center" style="margin-left: 30px;" dir="auto">
              <hr class="hLine1" style="width:10%;">
              <p class="pLine" style="font-size: 15px;">{{'changpassword'|translate}}</p>
              <hr class="hLine1" style="width:10%; margin-right: 30px;">
          </div>

          <!--Change password textbox-->
          <div class="container-fluid">
              <div>
                <form>
                    <div class="mb-3 text-justify" dir="auto">
                      <label for="newPassTXT" id="newPassLabel" class="form-label text-left justify-content-left d-flex">{{'YourNewPassword'|translate}}</label>
                      <input type="password" class="form-control" id="newPassTXT" placeholder="* * * * * * * *" #newpassword="ngModel"
                      [(ngModel)]="password" name = "password">
                      <div id="newPasswarnTXT" class="form-label text-justify justify-content-left d-flex"></div>
                    </div>

                    <!--This will save the new password to the user's profile-->
                    <button id="saveNewPass" type="submit" class="btn btn-primary" (click)="save()">{{'Save'|translate}}</button>
                  </form>
              </div>
          </div>

          <div class="d-flex">
            <hr class="hLine1" style="width: 24%;">
            <p class ="pLine" style="font-size: 13px;" class="font-weight-bold">{{'DeleteYourAccount'|translate}}</p>
            <hr class="hLine2" style="width: 24%;">
          </div>


          <!--Delete Btn--> <!--This will delete the account-->
          <button id="deleteBtn" type="btn" class="text-white" (click)="deleteBtn()">{{'Delete'|translate}}</button>
          <br>

          <!--Bo back btn--> <!--This will go to the main edit profile page-->
          <a id="gobackLink" class="text-center font-weight-bold" (click)="goBack()">{{'Goback'|translate}}</a>
          <br>
          <br>

        </div>

        <!--Right Indentation-->
        <div class="col">
        </div>
    </div>
</div>