        <!-- Start Body Main -->
        <div class="container-fluid main">
            <div class="container-fluid nBar text-center py-2 align-self-center" dir="auto">
                <img src="assets/images/lodoNav.JPG" style="width: 55px;margin-right: 10px;">
                <span>{{'VIP.FREE' |translate}}*</span>
                <a href="javascript:void(0)">{{'VIP.shop' |translate}}</a>
            </div> 
            <div class="container-fluid content " dir="auto">
                <a href="javascript:void(0)">{{'VIP.Home' |translate}}</a>
                <span><i class="fa fa-angle-right"></i></span>
                <span>VIP</span>
            </div>  

            
            <div class="container-fluid">
                <div class="blue">
                    <div class="container">
                        <div class="row">
                            <div class="col-md col-12 text-justify" dir="auto">
                                <div class="blackBlue  px-4" dir="auto">
                                    <h2>{{'VIP.Rewarded' |translate}}</h2>
                                    <div class="btn-group" role="group">
                                        <button type="button" class="btn">{{'VIP.Rewards' |translate}}</button>
                                        <button type="button" class="btn butt2" routerLink="/EditAccount">{{'VIP.Online' |translate}}</button>
                                    </div>
                                    <div><img class="imgBlue" src="assets/images/VIP.png" alt="VIP"></div>
                                    <div class="row customFont">
                                        <div class="col-md-5">
                                            <p>{{'VIP.HaveAccount' |translate}}</p>
                                        </div>
                                        <div class="col-md-7">
                                            <button type="button" class="btn" routerLink="/EditAccount">{{'wishList.SignIn' |translate}} <span>></span></button>
                                        </div>
                                    </div>
                                    <hr>
                                    <ul style="list-style-type:square;padding-left: 18px !important;padding-right: 22px !important;">
                                        <li>{{'VIP.Earn' |translate}}</li>
                                        <li>{{'VIP.Unlock' |translate}}</li>
                                        <li>{{'VIP.Exclusive' |translate}}</li>
                                        <li>{{'VIP.gifts' |translate}}</li>
                                        <li>{{'VIP.Receive' |translate}}</li>
                                    </ul>
                                 
                                </div>
                            </div>
                            <div class="col-md col-12">
                                <img src="assets/images/King_VIP_Guide-min.png" style="width: 100%;">
                            </div>
                            <div>
                                <a class="carousel-control-prev pb-3" href="#carouselExampleControls" role="button" data-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="sr-only">{{'RecommendedForYou.Previous'|translate}}</span>
                                  </a>
                                  <a class="carousel-control-next pb-3" href="#carouselExampleControls" role="button" data-slide="next">
                                    <span class="carousel-control-next-icon " aria-hidden="true"></span>
                                    <span class="sr-only">{{'RecommendedForYou.Next'|translate}}</span>
                                  </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="container-fluid">
                <div class="next">
                    <div class="container-fluid">
                        <div class="row" >
                            <div class="col-6 fontdiv text-justify" dir="auto" >
                                <img class="text-justify" dir="auto"src="assets/images/logo 1997.JPG" alt="">
                                <h2 class="text-justify" dir="auto">{{'VIP.limited' |translate}}</h2>
                                <p class="text-justify" dir="auto">{{'VIP.chance' |translate}}</p>
                                <button class="btn" style="font-size: 20px;width: 200px;" data-toggle="modal" data-target="#exampleModalLong">{{'VIP.more' |translate}} <span>></span></button>
                            </div>
                            <img class="col-6" src="assets/images/Originals_HighSnobiety-Home-202012-Hero-Standard-Large2.webp" width="100%">
                        </div>
                    </div>
                </div>      
            </div>  
            <div class="container-fluid" style="background: #094DAC;padding: 0 !important;">
                <div class="container-fluid text-center link">
                    <div class="row" style="padding-top: 10px;padding-bottom: 40px;">
                        <div class="col-6 text-right">
                            <a href="javascript:void(0)"><img src="assets/images/VIP_Landing_Page_Quicklink_-_Terms_and_C.webp">
                                <br><br><span style="margin-left: 10px;">{{'VIP.Terms' |translate}}</span></a>
                        </div>
                        <div class="col-6 text-left">
                            <a href="javascript:void(0)"><img src="assets/images/VIP_Landing_Page_Quicklink_-_FAQs.webp">
                                <br><br><span style="margin-left: 40px;">{{'VIP.FAQs' |translate}}</span></a>
                        </div>
                    </div>
                </div>
            </div>  
            <div class="container-fluid" style="background: #F8F8F8;">
                <div>
                    <div class="container-fluid">
                        <div class="relative" style="position: relative;">
                            <img src="assets/images/VIP_Landing_Page_Hero-Tall-Large.webp" width="100%">
                            <div class="text-justify" dir="auto">
                                <h2>{{'VIP.WhatVIP' |translate}}</h2>
                                <p>{{'VIP.join' |translate}}</p>
                            </div>
                        </div>
                    </div>
                </div>      
            </div> 
            <div class="container-fluid">
                <div class="row py-4 sec3 text-justify" dir="auto">
                    <div class="col-6">
                        <div><img src="assets/images/VIP_-_Icon_-_Crown.jpeg"></div>
                        <h3>{{'VIP.BeVIP' |translate}}</h3>
                        <p>{{'VIP.collecting' |translate}}</p>
                    </div>
                    <div class="col-6">
                        <div><img src="assets/images/VIP_Landing_Page_CopyContent_-_Points.png"></div>
                        <h3>{{'VIP.SPENT' |translate}}</h3>
                        <p>{{'VIP.flexible' |translate}}</p>
                    </div>
                    <div class="col-6">
                        <div><img src="assets/images/VIP_Landing_Page_CopyContent_-_Online_Rewards.png"></div>
                        <h3>{{'VIP.REWARDS' |translate}}</h3>
                        <p>{{'VIP.online' |translate}}</p>
                    </div>
                    <div class="col-6">
                        <div><img src="assets/images/VIP_Landing_Page_CopyContent_-_Early_Access.png"></div>
                        <h3>{{'VIP.ACCESS' |translate}}</h3>
                        <p>{{'VIP.EarlyAccess' |translate}}</p>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="background: #F8F8F8;padding: 0;">
                <div class="next" style="background: #F8F8F8;">
                    <div class="container-fluid">
                        <div class="relative2">
                            <img src="assets/images/Capture.JPG">
                            <div class="text-justify" dir="auto">
                                <h2 style="font-weight: 400;">{{'VIP.TodayBuild' |translate}}</h2>
                                <p>{{'VIP.following' |translate}}</p>
                                <p style="font-weight: 375;">{{'VIP.stores' |translate}}</p>
                                <button class="btn" routerLink="/EditAccount">{{'VIP.joinNow' |translate}}<span>></span></button>
                            </div>
                        </div>
                    </div>
                </div>      
            </div>
            <div class="container-fluid text-justify" dir="auto">
                <h2 style="font-weight: 350;">{{'VIP.Centre' |translate}}</h2>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-6">
                        <div style="position: relative;">
                            <img src="assets/images/VIP_Landing_Page_Mixed-Block-Large.webp" style="width: 100%;">
                            <div class="text-justify" dir="auto" style="position: absolute; left: 50px; top: 50px; z-index: 0;font-size: 16px;font-weight: 400;transform: scale(0.9);">
                                <p style="font-weight: 700;">{{'VIP.Really' |translate}}</p>
                                <p class="ml-4 real">{{'VIP.uniqueDigital' |translate}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 row">
                        <div class="container col-11 scal mx-md-2 my-md-1 ml-1 mb-1">
                            <div class="row" style="background: #53BAE0;">
                                <img class="col-6 mr-auto" src="assets/images/VIP_Landing_Page_Mixed-Block-Large_2.webp" style="padding: 0 !important;">
                                <div  class="col-6 text-justify" dir="auto" style="font-size: 16px;font-weight: 400;transform: scale(0.9);">
                                    <p style="font-weight: 700;">{{'VIP.Redeem' |translate}}</p>
                                    <p class="m-md-4">{{'VIP.Once' |translate}}</p>
                                    <span class="dis">{{'VIP.Redeem' |translate}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="container col-11 scal mx-md-2 my-md-4 pt-md-3 ml-1">
                            <div class="row" style="background: #53BAE0;">
                                <div class="col-6 text-justify" dir="auto" style="font-size: 16px;font-weight: 400;transform: scale(0.9);">
                                    <p style="font-weight: 700;">{{'VIP.Points' |translate}}</p>
                                    <p class="ml-md-4 m-0">{{'VIP.balance' |translate}}</p>
                                    <span class="dis">{{'VIP.Points' |translate}}</span>
                                </div>
                                <img  class="col-6 ml-auto" src="assets/images/VIP_Landing_Page_Mixed-Block-Large_3.webp" style="padding: 0 !important;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-3 text-center vipDark" >
                <h2 style="padding-top: 50px;">{{'VIP.VIPEASY' |translate}}</h2>
                <div class="text-justify" dir="auto">
                    <h2>{{'VIP.UPOrIN' |translate}}</h2>
                    <p>{{'VIP.store' |translate}}</p>
                </div>
                <div class="text-justify" dir="auto">
                    <h2>{{'VIP.COLLECT' |translate}}</h2>
                    <p>{{'VIP.NotOnly' |translate}} </p>
                </div>
                <div class="text-justify" dir="auto">
                    <h2>{{'VIP.AWESOME' |translate}}</h2>
                    <p>{{'VIP.Visit' |translate}}</p>
                </div>
                <button class="btn" routerLink="/EditAccount">{{'VIP.joinNow'|translate}} <span><i class="fa fa-angle-right"></i></span></button>
                <img src="assets/images/VIP_Landing_Page_Gallery-Standard-Large.webp">
            </div>
            <!-- The End -->
        </div>
        <!-- End Body Main -->

        

<!-- Modal -->
<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
aria-hidden="true">
<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">{{'LegoLife.Life' | translate}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>{{'modal1'|translate}}</p>
            <p>{{'modal2'|translate}}</p>
            <p>{{'modal3'|translate}}</p>
            <p>​{{'modal4'|translate}}</p>
            <p>{{'modal5'|translate}}</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'User.Close'|translate}}</button>
        </div>
    </div>
</div>
</div>
<!-- Modal -->